import React, { useEffect, useRef } from 'react'

export default function EmptyBar(props) {
    const projectsRef = useRef(null);

    useEffect(() => {
        // Check if there's a hash in the URL
        const hash = window.location.hash;
        if (hash && projectsRef.current) {
            // Scroll to the referenced element (assuming it has the 'id' attribute)
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div id={props.id} style={{
            height: props.height,
            width: '100%'
        }}> </div >
    )
}
