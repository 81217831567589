import React, { Component } from 'react';
import axios from 'axios';
import { TextField, Button, IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import { CustomView, isMobile, isMobileOnly, MobileOnlyView } from 'react-device-detect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Slider from '@material-ui/core/Slider';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const aaa = createMuiTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                color: 'white',
            },
        },
    }
});
const muiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "rgb(255, 202, 138)",
            },
            track: {
                color: 'white'
            },
            rail: {
                color: '#ccc'
            },
            markLabel: {
                color: '#eee'
            },
            markLabelActive: {
                color: 'rgb(255, 202, 138)'
            },
            valueLabelDisplay: {
                color: 'red'
            },

        }
    }
});


class Talk2Me extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputText: '',
            canPress: true,
            bgcolor: 'black',
            cursorType: 'default',
            mode1: 'normal',
            mode2: 'normal',
            open: false,
            contextLength: 9,
            history: [
                { role: 'assistant', content: "Hello there! Make sure to have your speaker volume on so you can hear me speak." },
                { role: 'assistant', content: "Important note: while I was created by Ishaan Javali, I don't have his complete knowledge nor vocal abilities and I will make mistakes and statements not reflective of Ishaan. Nevertheless, I'll answer your questions to the best of my abilities, as he would, and I look forward to our conversation! (Your conversation is confidential and never saved)" },
            ],
        };
        this.marks = [
            {
                value: 3,
                label: 3,
            },
            {
                value: 5,
                label: 5,
            },
            {
                value: 9,
                label: 9,
            },
            {
                value: 13,
                label: 13,
            },
        ];
        this.ref = React.createRef();

        // this.textToSpeech("Hello! I'm Ishaan Javali and I'm passionate about all things tech. I'm excited to talk to you!").then((data) => {
        //     const audio = new Audio(URL.createObjectURL(new Blob([data], { type: 'audio/mpeg' })));
        //     audio.playbackRate = 1.05
        //     audio.play();
        // });
    }
    valuetext = (value) => {
        return `${value}`;
    }

    valueLabelFormat = (value) => {
        return value;
    }


    textToSpeech = async (inputText) => {
        // Set the API key for ElevenLabs API. 
        // Do not use directly. Use environment variables.
        const API_KEY = process.env.REACT_APP_ELEVEN_LABS
        // Set the ID of the voice to be used.
        const VOICE_ID = process.env.REACT_APP_VOICE_ID

        // Set options for the API request.
        const options = {
            method: 'POST',
            url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
            headers: {
                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                'content-type': 'application/json', // Set the content type to application/json.
                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
            },
            data: {
                text: inputText, // Pass in the inputText as the text to be converted to speech.
                // model_id: "eleven_multilingual_v1",
                stability: 0.41,
                similarity_boost: 0.85, // Set the similarity boost to 0.5.
            },
            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
        };

        // Send the API request using Axios and wait for the response.
        const speechDetails = await axios.request(options);

        // Return the binary audio data received from the API response.
        return speechDetails.data;
    };

    getPromptEngineered = () => {
        const prompt = process.env.REACT_APP_PROMPT
        return prompt;
    }

    getGPTResponse = async (inputText) => {
        const API_URL = "https://api.openai.com/v1/chat/completions";
        const API_KEY = process.env.REACT_APP_OPEN_AI;

        const messages = []
        const initial = {
            role: 'user', content: this.getPromptEngineered()
        };
        messages.push(initial);

        let systemMessage = "";
        for (let i = Math.max(2, this.state.history.length - this.state.contextLength);
            i < this.state.history.length; i++) {
            if (this.state.history[i].role === 'system')
                systemMessage = this.state.history[i];
            else
                messages.push(this.state.history[i]);
        }
        if (systemMessage !== "")
            messages.push(systemMessage);

        messages.push({ role: 'user', content: inputText });

        const newHistory = this.state.history;
        newHistory.push({ role: 'user', content: inputText });
        this.setState({ history: newHistory })
        const result = await fetch(API_URL, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`,
            },
            body: JSON.stringify({
                model: "gpt-3.5-turbo",
                messages: messages,
                max_tokens: 900,
            }),
        });
        if (this.ref.current) {
            this.ref.current.scrollTop = this.ref.current.scrollHeight;
        }
        const response = await result.json();
        // console.log(response);
        // update title
        let title = response.choices[0].message.content;
        return title;
    }

    handleClick = () => {
        this.setState({ inputText: '' });
        this.setState({ canPress: false, bgcolor: 'grey', cursorType: 'wait' })
        const response = this.getGPTResponse(this.state.inputText).then((text) => {
            let newHistory = this.state.history;
            const [firstPart, secondPart] = this.splitTextAtHalfWithSentences(text);

            if (text.length > 570 && secondPart.length > 2) {
                this.textToSpeech(firstPart).then((data) => {
                    newHistory.push({ role: 'assistant', content: text })
                    this.setState({ history: newHistory })
                    if (this.ref.current) {
                        this.ref.current.scrollTop = this.ref.current.scrollHeight;
                    }
                    // console.log(data);
                    this.setState({ canPress: true, bgcolor: 'black', cursorType: 'default' })
                    const audio = new Audio(URL.createObjectURL(new Blob([data], { type: 'audio/mpeg' })));
                    audio.playbackRate = 1.085
                    audio.play();
                    console.log("WASSUP")
                    let audio2 = null;
                    this.textToSpeech(secondPart).then((data2) => {
                        audio2 = new Audio(URL.createObjectURL(new Blob([data2], { type: 'audio/mpeg' })));
                        audio2.playbackRate = 1.088
                    });
                    audio.addEventListener("ended", function () {
                        if (audio2 != null)
                            audio2.play();
                    });
                });
            } else {
                this.textToSpeech(text).then((data) => {
                    newHistory.push({ role: 'assistant', content: text })
                    this.setState({ history: newHistory })
                    if (this.ref.current) {
                        this.ref.current.scrollTop = this.ref.current.scrollHeight;
                    }
                    // console.log(data);
                    this.setState({ canPress: true, bgcolor: 'black' })
                    const audio = new Audio(URL.createObjectURL(new Blob([data], { type: 'audio/mpeg' })));
                    audio.playbackRate = 1.085
                    audio.play();
                });
            }
        });


    }
    splitTextAtHalfWithSentences = (text) => {
        // Step 1: Split the text into an array of sentences based on sentence-ending punctuation marks.
        let sentences = text.match(/[^.!?]+[.!?]+/g);

        if (!sentences || sentences.length < 2) {
            // If there are less than two sentences, return the original text as the first part,
            // and an empty string as the second part (no splitting needed).
            return [text, ''];
        }

        // Step 2: Calculate the halfway index of the characters in the original text.
        let totalChars = text.length;
        let halfwayIndex = Math.ceil(totalChars * 0.55);

        // Step 3: Combine sentences until the halfway index is reached or slightly exceeded.
        let firstPart = '';
        let secondPart = '';
        let combinedLength = 0;

        for (const sentence of sentences) {
            // Check if adding the current sentence to the firstPart will exceed or equal the halfway index.
            if (combinedLength + sentence.length <= halfwayIndex) {
                firstPart += sentence;
                combinedLength += sentence.length;
            } else {
                // If it exceeds the halfway index, stop and put the rest in the secondPart.
                secondPart = text.slice(combinedLength);
                break;
            }
        }
        if (firstPart.length <= Math.ceil(totalChars * 0.45)) {
            halfwayIndex = Math.ceil(totalChars * 0.6);

            // Step 3: Combine sentences until the halfway index is reached or slightly exceeded.
            firstPart = '';
            secondPart = '';
            combinedLength = 0;

            for (const sentence of sentences) {
                // Check if adding the current sentence to the firstPart will exceed or equal the halfway index.
                if (combinedLength + sentence.length <= halfwayIndex) {
                    firstPart += sentence;
                    combinedLength += sentence.length;
                } else {
                    // If it exceeds the halfway index, stop and put the rest in the secondPart.
                    secondPart = text.slice(combinedLength);
                    break;
                }
            }
        }

        // Return both parts as an array of two elements.
        return [firstPart, secondPart];
    }

    renderHistory = () => {
        return this.state.history.map((item, index) => {
            const { role, content } = item;
            const isAssistant = role === 'assistant';
            const textAlign = isAssistant ? 'left' : 'right';
            const fontSize = isMobile ? '15px' : '18px';
            const spacing = isMobile ? '20%' : '49%'
            const sideMargin = isMobile ? '10px' : '20px';
            const paddingMobile = isMobile ? '8px' : '10px';

            const commonStyle = {
                fontSize: fontSize, borderRadius: '10px', width: 'auto'
                , display: 'inline-block', padding: paddingMobile
            }
            if (index !== this.state.history.length - 1)
                commonStyle.marginBottom = '-5px';
            else
                commonStyle.marginBottom = '5px';

            if (isAssistant) {
                return (
                    <p key={item.id} style={{
                        ...commonStyle, marginLeft: sideMargin, marginRight: spacing,
                        background: '#ddd',
                    }}>
                        {content}
                    </p>
                );

            } else if (role === 'user') {
                return (
                    <div style={{ textAlign: 'right', }}>
                        <p key={item.id} style={{
                            ...commonStyle, marginRight: sideMargin,
                            marginLeft: spacing
                            , color: '#fff', background: '#cc8304'
                        }}>
                            {content}
                        </p>
                    </div>
                );
            }
        });

    }

    handleChange = (event) => {
        this.setState({ inputText: event.target.value });
    }
    handleModeSelection1 = (event) => {
        this.setState({ mode1: event.target.value });
    }
    handleModeSelection2 = (event) => {
        this.setState({ mode2: event.target.value });
    }
    sliderChange = (event, newValue) => {
        this.setState({ contextLength: newValue });
    }

    getDialog = () => {

        return (<Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
            <div style={{ background: 'black', display: 'flex', flexDirection: 'column', }}>
                <div style={{ background: 'linear-gradient(135deg, rgba(255, 202, 138, 0.23), rgba(255,255,255,0.2), rgba(199, 255, 200, 0.23))' }}>
                    <DialogTitle id="simple-dialog-title" style={{ color: 'white' }}><b>Adjust my settings!</b></DialogTitle>
                    <FormControl component="fieldset" style={{ marginTop: '15px' }}>
                        <FormLabel component="legend" style={{ color: '#ccc', marginLeft: '35px', marginTop: '16px', marginBottom: '3px' }}>Change my tone</FormLabel>
                        <RadioGroup aria-label="gender" value={this.state.mode1} name="gender1" onChange={this.handleModeSelection1} style={{ textAlign: 'center' }}>
                            <ThemeProvider theme={aaa}>
                                <FormControlLabel style={{ marginLeft: '50px' }} value="normal" control={<Radio />} label="Normal (formal)" />
                            </ThemeProvider>
                            <FormControlLabel style={{ marginLeft: '50px', }} value="casual" control={<Radio />} label="Casual" />
                            <FormControlLabel style={{ marginLeft: '50px', }} value="funny" control={<Radio />} label="Lighthearted" />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <FormControl component="fieldset" style={{ marginTop: '15px' }}>
                        <FormLabel component="legend" style={{ color: '#ccc', marginLeft: '35px', marginTop: '16px', marginBottom: '3px' }}>Response Length</FormLabel>
                        <RadioGroup aria-label="gender" value={this.state.mode2} name="gender1" onChange={this.handleModeSelection2} style={{ textAlign: 'center' }}>
                            <FormControlLabel style={{ marginLeft: '50px' }} value="short" control={<Radio />} label="Short" />
                            <FormControlLabel style={{ marginLeft: '50px', }} value="normal" control={<Radio />} label="Normal" />
                            <FormControlLabel style={{ marginLeft: '50px', marginBottom: '13px' }} value="lengthy" control={<Radio />} label="Lengthy" />
                        </RadioGroup>
                    </FormControl>
                    <FormLabel component="legend" style={{ color: '#ccc', marginLeft: '35px', marginTop: '16px', marginBottom: '3px' }}>Memory (how many<br />  messages I remember)</FormLabel>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <MuiThemeProvider muiTheme={muiTheme}> */}
                        <ThemeProvider theme={muiTheme}>
                            <Slider
                                defaultValue={this.state.contextLength}
                                valueLabelFormat={this.valueLabelFormat}
                                getAriaValueText={this.valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={null}
                                min={3}
                                max={13}
                                onChange={this.sliderChange}
                                marks={this.marks}
                                style={{
                                    textAlign: "center",
                                    color: "red",
                                    width: "80%",
                                }}
                                classes={{
                                    valueLabel: {
                                        color: "red",
                                    },
                                }}
                            />
                        </ThemeProvider>
                        {/* </MuiThemeProvider> */}
                    </div>
                </div>
            </div>
        </Dialog>)
    }
    handleClose = () => {
        //save everything
        let message = "";
        if (this.state.mode1 === 'normal') {
            message = "Speak in a semi-formal tone to the recruiter. Still feel free to use exclamation marks"
        } else if (this.state.mode1 == 'casual') {
            message = "Speak in a casual tone. Use exclamation marks openly."
        } else {
            message = "Speak in a lighthearted, joking tone. Feel free to be funny and excited. Crack jokes where applicable. Use exclamation marks openly."
        }
        if (this.state.mode2 === 'short') {
            message += "The recruiter wants short responses. Be very concise 30 words or less."
        } else if (this.state.mode2 === 'normal') {
            message += "Respond to the recruiter in an adequate, concise length. Adhere to 30-50 words.";
        } else {
            message += "Respond in great detail. Use as many words as you wish."
        }
        let newHistory = [];
        for (let i = 0; i < this.state.history.length; i++) {
            if (this.state.history[i].role !== 'system')
                newHistory.push(this.state.history[i]);
        }
        newHistory.push({ role: 'system', content: message })
        this.setState({ open: false, history: newHistory });
    }

    launchDialog = () => {
        this.setState({ open: true });
    }

    render() {
        return (
            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh',
                // background: '#fff' ,
                backgroundImage: 'linear-gradient(45deg, #feffe8, white, #f8f2ff)'
            }}>
                {this.getDialog()}
                <MobileOnlyView style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh',
                    // background: '#fff' ,
                    backgroundImage: 'linear-gradient(45deg, #feffe8, white, #f8f2ff)'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px', width: '100%' }}>
                        <h1 style={{ fontSize: '29px' }}>Talk to Me</h1>
                    </div>
                    <div style={{ color: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-21px', width: '100%' }}>
                        <p><i>Enter text & hear me talk! (might take a few seconds)</i></p>
                    </div>
                    <div ref={this.ref} style={{
                        width: '95%', height: '90vh', overflowY: 'auto',
                        background: '#fff9ed', alignItems: 'center', justifyContent: 'center', borderRadius: '20px'
                        , border: '3px solid #ffde9c', cursor: this.state.cursorType
                    }}>
                        {this.renderHistory()}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px', marginBottom: '10px', width: '100%' }}>
                        <TextField
                            multiline
                            rowsMax={4}
                            rows={1}
                            type="text"
                            value={this.state.inputText}
                            onChange={this.handleChange}
                            placeholder="Type a message: (e.g., Tell me about yourself)"
                            style={{ marginRight: '10px', width: '60%' }}
                        />
                        <Button variant="contained" style={{ background: this.state.bgcolor }} onClick={this.handleClick}
                            disabled={!this.state.canPress}>
                            Enter
                        </Button>
                        <IconButton style={{ marginLeft: '0px', }} onClick={this.launchDialog}>
                            <SettingsIcon style={{ color: '#f59505' }} />
                        </IconButton>
                    </div>
                </MobileOnlyView>

                <CustomView condition={isMobileOnly === false} style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh',
                    // background: '#fff' ,
                    backgroundImage: 'linear-gradient(45deg, #feffe8, white, #f8f2ff)'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px', width: '100%' }}>
                        <h1>Talk to Me</h1>
                    </div>
                    <div style={{ color: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-10px', width: '100%' }}>
                        <p><i>Enter some text and hear me talk! (might take a few seconds)</i></p>
                    </div>
                    <div ref={this.ref} style={{
                        width: '70%', height: '90vh', overflowY: 'auto',
                        background: '#fff9ed', alignItems: 'center', justifyContent: 'center', borderRadius: '20px'
                        , border: '3px solid #ffde9c', cursor: this.state.cursorType
                    }}>
                        {this.renderHistory()}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px', marginBottom: '10px', width: '100%' }}>
                        <TextField
                            multiline
                            rowsMax={4}
                            rows={1}
                            type="text"
                            value={this.state.inputText}
                            onChange={this.handleChange}
                            placeholder=" Enter a message: (e.g., Tell me about yourself)"
                            style={{ marginRight: '10px', width: '40%' }}
                        />
                        <Button variant="contained" style={{ background: this.state.bgcolor }} onClick={this.handleClick}
                            disabled={!this.state.canPress}>
                            Enter
                        </Button>
                        <IconButton style={{ marginLeft: '8px', }} onClick={this.launchDialog}>
                            <SettingsIcon style={{ color: '#f59505' }} />
                        </IconButton>
                    </div>
                </CustomView>

            </div >

        );
    }
}

export default Talk2Me;
