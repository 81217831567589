import React from 'react'

export default function Footer() {
    return (
        <div style={divStyle}>
            <div>
                <p style={textStyle} onClick={() => window.open("https://github.com/ishaanjav")}>
                    <em>Coded with <span style={{ color: "#fff0c4", fontWeight: '660' }}>React</span></em>
                </p>
            </div>
        </div>
    )
}

const divStyle = {
    width: '100%',
    height: '45px',
    // background: '#fff',
    background: 'rgb(8, 144, 255)',
    paddingBottom: '9px',
    paddingTop: '1px',
}
const textStyle = {
    // color: 'black',
    color: 'white',
    fontSize: '18px',
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: '600'
}
