import React, { Component } from 'react'
import NavigationBar from '../components/NavigationBar'
import EmptyBar from '../other/EmptyBar'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import About from '../components/About'
import Awards from '../components/Awards'
import Resume from '../components/Resume'
import Projects from '../components/Projects'
import Test from '../components/Test'
import { CustomView, isMobile, isMobileOnly, MobileOnlyView } from 'react-device-detect';
import MobileNavBar from '../components/MobileNavBar'
import { colors } from '../constants/Constants.js'
import ReactDOM from 'react-dom'
import yellowshiny from '../backgrounds/yellowshiny.svg'

// if height of contact and screen hiehgt differe by < 90, then stop aligning footer at bottom
var navOffSet = 0, threshold = 100;
class Template extends Component {

    updateDimensions = () => {
        var res = this.calculate();
        // don't align to bottom
        if (res < threshold) {
            this.setState({ style: footDiv2 })
            this.setState({ margin: '60px' })
            this.setState({ bottom: '0px' })
            this.setState({ type: divStyle2 })
        } else {
            // align to bottom
            this.setState({ style: footDiv })
            this.setState({ margin: '0px' })
            this.setState({ bottom: '0px' })
            this.setState({ type: divStyle })
        }
        // console.log("Res: ", res)
        // console.log("Margin: ", this.state.margin)
        // this.setState({ margin: margin + 'px' });
    };
    calculate() {
        var w = window.innerWidth;
        var h = window.innerHeight;
        // this.setState({ width: window.innerWidth, height: window.innerHeight });
        // console.log(ReactDOM
        //     .findDOMNode(this.refs['box'])
        //     .getBoundingClientRect());

        // console.log("Waa " + h + " " + navOffSet);
        var amt = h - ReactDOM
            .findDOMNode(this.refs['box'])
            .getBoundingClientRect().height;
        // console.log("SET " + amt)
        return amt;
    }
    constructor(props) {
        super(props)
        this.state = {
            margin: '0px',
            bottom: '0px',
            style: footDiv,
            type: divStyle,
        }
    }
    componentDidMount() {
        // var w = window.innerWidth;
        // var h = window.innerHeight;
        // // this.setState({ width: window.innerWidth, height: window.innerHeight });
        // this.box = React.createRef()
        // console.log(ReactDOM
        //     .findDOMNode(this.refs['box'])
        //     .getBoundingClientRect());
        // console.log("Waa " + h);
        this.box = React.createRef()
        this.nav = React.createRef()
        this.updateDimensions()
        /* var res = this.calculate()
        if (res < threshold) {
            this.setState({ style: footDiv2 })
        } else {
            this.setState({ style: footDiv })
        } */

        // this.setState({ margin: this.calculate() + 'px' })
        // this.navOffset = ReactDOM
        //     .findDOMNode(this.refs['nav'])
        //     .getBoundingClientRect().height;
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    render() {
        return (
            <div style={{
                // background: colors.contact,
                backgroundImage: `url(${yellowshiny})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundAttachment: 'scroll',
                alignItems: 'center', ...this.state.type,
                height: '100vh'
            }}>
                {/* TODO: Need to make nav bars for non home page */}
                <div ref="nav" style={{ width: '100%' }}>
                    <MobileOnlyView>
                        <MobileNavBar />
                    </MobileOnlyView>
                    <CustomView condition={isMobileOnly === false}>
                        <NavigationBar />
                    </CustomView>

                </div>
                {/* <EmptyBar height='80px' /> */}

                {/* <EmptyBar height='160px' /> */}

                <div style={{
                    ...this.state.type,
                    paddingTop: this.state.margin,
                    paddingBottom: this.state.bottom,
                    // background: colors.contact,
                    flexDirection: 'column',
                    marginTop: '-10px'
                }}>
                    <Contact ref="box" use={true} />

                </div>
                <div style={{
                    ...this.state.style,
                    // background: colors.contact
                }}>
                    <Footer />
                </div>
                {/* <EmptyBar height='80px' /> */}
            </div >
        )
    }
}
//  aligned to bottom
const footDiv = {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    // background: colors.contact
}
// no align. Just regular below contact
const footDiv2 = {
    // marginTop: '19px',
    width: '100%',
    position: 'relative',
    // background: colors.contact
    // flexDirection:'column'
}
const divStyle = {
    // uncomment v makes contact full screen and have to scroll to footer
    // height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'middle',
    alignItems: 'center',
    justifyContent: 'center',
}
const divStyle2 = {
    // uncomment v makes contact full screen and have to scroll to footer
    // height: '100vh',
    width: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    // verticalAlign: 'middle',
    // alignItems: 'center',
    // justifyContent: 'center',
}
export default Template