import React from 'react'
// import googs from '../images/awards/googs.png'
// import waves from './wavelight.svg'
// import waves from './wavedark.svg'
// import waves from './wave3.svg'
// import waves from './twowaves.svg'
// import waves from './twowavesonecol.svg'
import waves from './lbasic2.svg'
import yellowshiny from './yellowshiny.svg'

function Wave(props) {
    return (
        <div style={{
            height: props.height, width: '100vw',
            backgroundImage: `url(${waves})`,
            // backgroundImage: `url(${yellowshiny})`,

            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            backgroundAttachment: 'scroll',
            marginBottom: '0px',
            marginTop: '-33px',
        }}>
            {/* <img src={waves} style={{ width: '100vw', height: '20vh', objectFit: 'cover' }}></img> */}
        </div>
    )
}

export default Wave
