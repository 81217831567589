import React, { Component } from 'react'
import data from '../images/awards/data.png'
import googs from '../images/awards/googs.png'
import { colors, spacing } from '../constants/Constants.js'
import AwardRow from '../text-items/AwardRow'
import "../styles/Awards.css"
import { styled } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

/* 
Dallas Data Challenge
That Harvard one
USACO Gold
AIME Qualifier
TXSEF Qualifier
Kiwanis Scholarship
*/

/* 
Looking at about another 27. So that's nice. Perfect 4 slides.

d - HackRice X - 1st place Data Science, 2nd overall
d - HooHacks 2021 - Data Science Winner
d - hackTAMS - 1st place (BirdWatch)
d - UTD Battle of the Brains - First place 2021 Fall, 2020 Fall, 2020 Spring, 2nd place
2019 Fall or just write 1st place 2x, 2nd place 1x
d - Codestellation Hackathon - Best Innovation Hack (BirdWatch)

d - Community AI Fair - Finalist
d - Clean Tech Competition - Semi Finalist
d-Sigma Xi Research Showcase - People's Choice Award

d- Purple Comet Math Meet - 1st team in Texas 

URTC if applicable

d - Google KickStart Round A - Temporarily 13th in the world!
Google Code Jam - Round 1 Qualifier 2x   <- Skip?
d - Texas Junior Science & Humanities Symposium (TJSHS) Presenter
d - Texas Junior Academy of Sciences (TJSHS) 3rd place 2x times
d - First Tech Challenge (Robotics) - Regional qualifier.


d - TAMU Power Team Contest - 7th

d - Best of Texas Written CS Contest - 1st place in state

d - Have 3 separate ones for UIL Comp Sci at each level
and put 1st individually and 1st team.
d - UIL Math 1st palce team in math for district.

Keller Programming Competition - 3rd in coding, 5th in written
d - 2020 11 - Clements HS Programming Competition - 1st in DFW
d - 2020 10 - Code LM HS Programming Competition - 1st place
d - Country Day HS Programming Competition - 1st place
d - Frisco First Bytes Programming Competition - 1st place
d - 2020 10 - Beta Club State Competition - 1st place
*/
/* 
Dropdown:
Science Fairs and Research Competitions
Programming Competitions
Hackathons
Math Competitions
Maybe make research a category based on # of things

TODO:
Figure out how to mention Best in State of Texas, naybe in its own tile, without being too repetitive.
  I don't want to have 2 TXSEF tiles. Maybe next line? Maybe small text on 3rd line, italicized "2nd Best in State for Physical Sciences (2021)"
NEED TO ADD DATES
Looking at this and they have no idea what you did now and what you did in the past.
Except for first 9 tiles, organize rest by date.
Show the date somewhere.
Or you know where you were thinking of having the dropdown, on the opposite side
put "10th grade". "11th grade" when they click.

CREATIVITY REALLY COOL HUMBLENESS:
Have something where if they reach the end of the list, it then disapperas
and shows text saying "Ah so you've made it to the end of the list. Now see my failures. or something"
But like "I don't always win. In fact, I have more failures than I do achievements, but I've learned from those achievements"
Then show like UTD Battle of the Brains 2018 Fall last place.
And things like that that can show improvment over time

Or if you don't do the failures section, at least add the 2019 Fall Last place next to the tile for first place
So title:"2018 battle of the Brains Last Place" text:"Click for more info" --> then describe your story.

Another one for Frisco First Bytes: Fall 2018. Close to last place.
*/

/* 
NEED:
Dropdown
Alert Dialog
Pagination

*/
/* 
TODO:
1. Figure out how to add margin to outer columns of f awards
*/

// Or Create your Own theme:
const theme = createMuiTheme({
    palette: {
        secondary: {
            // main: '#c2e4ff'
            // main: '#cfeaff'
            main: '#fff'
        }, root: {
            color: "#FFFFFF"
        }, allVariants: {
            color: "#fff"
        },
    }, typography: {
        allVariants: {
            color: "#fff"
        },
    },
});
var prev = 1

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const TTypography = withStyles({
    root: {
        color: "#000"
    }
})(Typography);

const Title = withStyles({
    root: {
        color: "#000"
    }
})(DialogTitle);

class Awards extends Component {

    handleChange = (e, newVal) => {
        // console.log(prev, newVal)
        if (prev == newVal) return;
        var idx = newVal - 1
        this.setState({ id: idx })
        prev = newVal;
    }
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.tileClicked = this.tileClicked.bind(this);
        this.setFalse = this.setFalse.bind(this);
        this.state = {
            id: 0,
            open: false,
            show: {
                'ISEF': false, 'USACO': false, 'AIME': false, 'TXSEF': false, 'Dallas': false,
                'Lockheed': false, 'Crown': false, 'SJWP': false, 'USAID': false, 'KickStart': false, 'SCALE': false, 'COSCON': false,
            },
            ISEF: false,
            USACO: false,
            AIME: false,
            TXSEF: false,
            Dallas: false,
            Lockheed: false,
            Crown: false,
            SJWP: false,
            USAID: false,
            KickStart: false,
            SCALE: false,
            COSCON: false,
            layout: [
                <div style={{ width: '87%', display: 'inline-block', zIndex: '100' }}>
                    <table>
                        <tr>
                            {/* <td><AwardRow name="" row={0}  place="ISEF" text="1st place & USAID Special Award" /></td> */}
                            <td ><AwardRow name="ISEF" t='S' row={0} place="ISEF" text="1st place" clicker={this.tileClicked} /></td>
                            <td><AwardRow name="USACO" t='C' row={0} place="USACO Gold" clicker={this.tileClicked} /></td>
                            <td> <AwardRow name="AIME" t='M' row={0} place="AIME" text="3x Qualifier" clicker={this.tileClicked} /></td>
                        </tr>
                        <tr>
                            <td> <AwardRow name="SCALE" t='S' row={2} text="2nd place" place="Scale AI Generative AI Hackathon" clicker={this.tileClicked} /></td>
                            <td><AwardRow name="USAID" t='S' row={2} place="USAID Special Award" text="" clicker={this.tileClicked} /></td>
                            <td  > <AwardRow name="KickStart" top="2px" t='C' size1="17px" size2="17px" t='C' row={1} text="Temporarily 13th! Finished 157th / 19800+ (top 0.8%)" place="Google KickStart Round A" clicker={this.tileClicked} /></td>

                        </tr>
                        <tr>
                            <td ><AwardRow name="Dallas" t='H' row={1} text="1st place" place="Dallas Data Challenge" clicker={this.tileClicked} /></td>


                            <td ><AwardRow name="TXSEF" t='S' row={1} place="TXSEF" text="3x Qualifier" clicker={this.tileClicked} /></td>
                            <td> <AwardRow name="COSCON" t='S' row={2} text="2nd place" place="Princeton CS Contest" clicker={this.tileClicked} /></td>
                            {/* <td ><AwardRow name="Lockheed" t='C' row={1} text="1st place" place="Lockheed Martin CodeQuest" clicker={this.tileClicked} /></td> */}
                            {/* <td><AwardRow name="" row={2}  place="Google"  text="Foobar Completed (Lvl 5)" /></td> */}
                        </tr>
                    </table>
                </div>,
                <div style={{ width: '87%', display: 'inline-block', }}>
                    <table>
                        <tr>
                            <td><AwardRow name="Crown" t='O' row={0} size1='18px' size2='18px' text="Finalist" place="Crown Education Challenge" clicker={this.tileClicked} /></td>
                            {/* <td><AwardRow name="" row={0}  place="ISEF" text="1st place & USAID Special Award" /></td> */}
                            <td ><AwardRow page="2" name="HackRice" t='H' size1='18px' size2='18px' row={0} place="HackRice X" text="1st place Data Science" below="2nd overall" clicker={this.tileClicked} /></td>
                            {/* <td><AwardRow name="UTD" t='C' row={1} size1='16px' size2='18px'  below="2nd 2019 Fall" text="1st 21 & 20 Fall, 20 Spring" place="UTD Battle of the Brains" clicker={this.tileClicked} /></td> */}
                            {/* <td  > <AwardRow name="KickStart" top="2px" t='C' size1="17px" size2="17px" t='C' row={0} text="Temporarily 13th! Finished 157th/19800+" place="Google KickStart Round A" clicker={this.tileClicked} /></td> */}
                            <td><AwardRow page="2" spacing="-13px" top="2px" name="UTD" t='C' row={0} size1='18px' size2='17px' below="2nd place 1x" text="1st place 2x" place="UTD Battle of the Brains" clicker={this.tileClicked} /></td>
                        </tr>
                        <tr>
                            <td ><AwardRow page="2" top="8px" name="Sigma" t='S' row={1} size1="18px" text="People's Choice Award" place="Sigma Xi Research Showcase" clicker={this.tileClicked} /></td>
                            <td ><AwardRow page="2" name="TAMS" t='H' row={1} text="1st place" place="hackTAMS" clicker={this.tileClicked} /></td>
                            <td> <AwardRow page="2" top="8px" name="Purple" t='M' row={1} place="Purple Comet Math Meet" text="1st place in Texas" clicker={this.tileClicked} /></td>
                        </tr>
                        <tr>
                            <td ><AwardRow page="2" name="HooHacks" t='H' row={1} place="HooHacks 2021" text="1st place Data Science" clicker={this.tileClicked} /></td>
                            <td ><AwardRow page="2" top="8px" size1='18px' name="Codestellation" t='H' row={2} text="Best Innovation Hack" place="Codestellation Hackathon" clicker={this.tileClicked} /></td>
                            {/* <td> <AwardRow name="" row={2}   place="SJWP Award" text="(2020)" /></td> */}
                            <td><AwardRow page="2" spacing="-11px" top="3px" size1="16px" name="TJSHS" tsize="19px" t='S' row={1} text="Presenter" place="Texas Junior Science & Humanities Symposium" clicker={this.tileClicked} /></td>
                            {/* <td><AwardRow name="" row={2}  place="Google"  text="Foobar Completed (Lvl 5)" /></td> */}
                        </tr>
                    </table>
                </div>,
                <div style={{ width: '87%', display: 'inline-block' }}>
                    <table>
                        <tr>
                            {/* <td><AwardRow name="" row={0}  place="ISEF" text="1st place & USAID Special Award" /></td> */}
                            <td  > <AwardRow page="2" top="8px" name="BoT" t='C' size1="19px" row={2} text="1st in state" place="Best of Texas Written CS Tests" clicker={this.tileClicked} /></td>
                            <td  ><AwardRow name="UILR" top="2px" t='C' size1="17px" size2="17px" row={2} place="UIL Regional Computer Science" text="1st individually" below="1st place team" clicker={this.tileClicked} /></td>
                            <td ><AwardRow name="UILD" top="2px" t='C' size1="17px" size2="17px" t='C' row={1} place="UIL District Computer Science" text="1st individually" below="1st place team" clicker={this.tileClicked} /></td>

                        </tr>
                        <tr>
                            <td ><AwardRow name="UILS" t='C' row={1} place="UIL State  Computer Science" text="4th place team" clicker={this.tileClicked} /></td>
                            <td ><AwardRow name="Community" t='S' row={1} place="Community AI Fair" text="Finalist" clicker={this.tileClicked} /></td>
                            <td  ><AwardRow top="5px" name="TJAS" t='S' tsize="20px" size1="17px" row={2} text="3rd 2x times" place="Texas Junior Academy of Sciences" clicker={this.tileClicked} /></td>
                        </tr>
                        <tr>
                            {/* <td> <AwardRow name="" row={2}   place="SJWP Award" text="(2020)" /></td> */}
                            {/* <td><AwardRow name="" row={2}  place="Google"  text="Foobar Completed (Lvl 5)" /></td> */}
                            <td  > <AwardRow name="Facebook" t='C' size1="18px" t='C' row={2} text="Round 2 Qualifier 2x" place="Facebook Hacker Cup" clicker={this.tileClicked} /></td>
                            <td  ><AwardRow name="TAMU" top="2px" size1="17px" t='M' row={2} place="TAMU Power Team Math Contest" text="2nd place" clicker={this.tileClicked} /></td>
                            <td> <AwardRow name="SJWP" t='S' row={2} place="Stockholm Junior Water Prize" clicker={this.tileClicked} /></td>
                            {/* <td> <AwardRow name="FTC" t='O' row={1} place="First Tech Challenge" text="Regional Qualifier" clicker={this.tileClicked} /></td> */}
                        </tr>
                    </table>
                </div>,
                <div style={{ width: '87%', display: 'inline-block' }}>
                    <table>
                        <tr>
                            {/* <td><AwardRow name="" row={0}  place="ISEF" text="1st place & USAID Special Award" /></td> */}
                            <td ><AwardRow name="UILM" t='M' row={2} text="1st place team" place="UIL District Math" clicker={this.tileClicked} /></td>
                            <td> <AwardRow name="Frisco" top="2px" size1="17px" t='C' row={1} place="Frisco First Bytes Programming Competition" text="1st place" clicker={this.tileClicked} /></td>
                            <td ><AwardRow name="Beta" t='O' row={1} text="3rd place Robotics" place="Beta Club State Competition" clicker={this.tileClicked} /></td>
                        </tr>
                        <tr>
                            <td ><AwardRow name="Clements" top="2px" size1="17px" t='C' row={1} place="Clements HS Programming Competition" text="1st place in DFW" clicker={this.tileClicked} /></td>
                            <td> <AwardRow name="Country" top="2px" size1="17px" t='C' row={1} place="Country Day HS Programming Competition" text="1st place" clicker={this.tileClicked} /></td>
                            <td ><AwardRow name="CodeLM" top="2px" size1="17px" t='C' row={1} place="Code LM HS Programming Competition" text="1st place" clicker={this.tileClicked} /></td>
                            {/* <td ><AwardRow name="Lockheed" t='C' row={1} text="1st place" place="Lockheed Martin CodeQuest" clicker={this.tileClicked} /></td> */}
                        </tr>
                        <tr>
                            <td ><AwardRow name="Keller" top="1px" size1="17px" size2="17px" t='C' row={2} text="3rd coding | 5th written" place="Keller Programming Competition" clicker={this.tileClicked} /></td>
                            {/* <td> <AwardRow name="" row={2}   place="SJWP Award" text="(2020)" /></td> */}
                            <td  > <AwardRow name="UTD2" t='C' row={2} text="Tied for last" place="UTD Battle of the Brains Fall 2018" clicker={this.tileClicked} /></td>
                            {/* <td><AwardRow name="" row={2}  place="Google"  text="Foobar Completed (Lvl 5)" /></td> */}
                        </tr>
                    </table>
                </div>
            ]
        }
    }
    tileClicked = (name) => {
        console.log("Clicked " + name)
        let showCopy = { ...this.state.show };
        showCopy[name] = true
        // showCopy['ISEF'] = true
        this.setState({ [name]: true })
    }
    handleClickOpen = () => {
        this.setState({ open: true })
    };
    handleClose = () => {
        this.setState({ open: false })
    };
    setFalse = (name) => {
        this.setState({ [name]: false })
    };
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <div
                    //  id="awardsScroll"
                    style={divStyle}>
                    <p style={{
                        fontSize: '34px',
                        fontWeight: '750',
                        // paddingTop:'20px', //added recently
                        marginTop: '-45px', // pre-motion
                        marginTop: '-55px', // for motion
                        color: 'white', userSelect: 'none'
                    }}>Awards</p>
                    {/* <p style={{ fontSize: '14px', marginTop: '-9px', marginBottom: '12px', color: '#fff', fontWeight: '630', userSelect: 'none' }}>
                        <i>The pages following the first are arranged roughly in reverse-chronological order (2021-2018).</i>
                    </p> */}
                    <p style={{ fontSize: '19px', marginTop: '-2px', marginBottom: '12px', color: '#fff4bf', fontWeight: '630', userSelect: 'none' }}>
                        Click on a tile to view info about how I got the award (details of my projects, etc)
                        <br></br>
                    </p>
                    {/* <table>
                    <tr>
                        <td> <AwardRow name="" row={0}   place="AIME" text="2x Qualifier" /></td>
                        <td><AwardRow name="" row={0}  place="USACO Gold" /></td>
                        <td><AwardRow name="" row={0}   place="TXSEF" text="3x Qualifier" /></td>
                    </tr>
                    <tr>
                        <td><AwardRow name="" row={1}  place="1st place" text="Dallas Data Challenge" /></td>
                        <td><AwardRow name="" row={1}  place="Finalist" text="Crown Education Challenge" /></td>
                        <td><AwardRow name="" row={1}   place="1st place" text="Lockheed Martin CodeQuest" /></td>
                    </tr>
                    <tr>
                        <td> <AwardRow name="" row={2}   place="SJWP" text="Award (2020)" /></td>
                        <td><AwardRow name="" row={2}  place="Google"  text="Foobar Completed (Lvl 5)" /></td>
                        <td><A wardRow name="" row={2}  text="Kiwanis Sophomore Scholarship" /></td>
                        </tr>
                    </table> */}
                    {this.state.layout[this.state.id]}

                    <br></br>
                    <div style={{
                        borderRadius: '10px', background: 'rgba(255,255,255,0.75)', display: 'inline-block',
                        marginTop: '8px', marginBottom: '-7px', zIndex: '1000'
                    }} onClick={this.handleClickOpen}>
                        <Pagination style={{ zIndex: '1000' }} count={4} shape="rounded" color="secondary" onChange={this.handleChange} />
                    </div>

                    <Dialog onClose={() => this.setFalse("ISEF")} aria-labelledby="customized-dialog-title" open={this.state.ISEF}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>ISEF   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Placed 1st in category at ISEF 2021 with my research project with my partner.
                                    <br></br>
                                    We created a Global Landslide Analytics System  accomplishing 3 tasks: </p>
                                <ol style={{ marginTop: '-15px' }}>
                                    <li>Global dataset of landslide incidences <b>and</b> climate and terrain features at time of landslide</li>
                                    <li>Landslide forecasting models with 86.9% TPR for 5-day forecasts</li>
                                    <li>Data-driven susceptibility mapping based off terrain features.</li>
                                </ol>
                                <p><u><b>Other Distinctions:</b></u></p>
                                <ul style={{ marginTop: '-15px' }}>
                                    <li><b>USAID Science for Development Award first place</b></li>
                                    <li><b>Presented at IEEE MIT URTC</b></li>
                                    <li><b>Paper published in IEEE Xplore</b></li>
                                    <li><b>Texas State Science & Engineering Fair:</b> 1st place category & 2nd place Grand Prize</li>
                                    <li>American Statistical Association Award</li>
                                    <li>Mu Alpha Theta Award</li>
                                </ul>
                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Click the buttons below to learn more about the project.</p> */}
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '20px' }} className="dialoglinks" onClick={() => window.open("Abstract%20and%20Poster.pdf")}>
                                    Abstract and Poster</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks" onClick={() => window.open("https://ishaanjavali.me/Research%20Paper.pdf")}>
                                    Research Paper</button>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={() => this.setFalse("USACO")} aria-labelledby="customized-dialog-title" open={this.state.USACO}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>USACO   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Qualified for the USACO Gold Division after
                                    several months of self-learning algorithms and data structures and practicing solving problems.</p>
                                {/* <p style={{ marginTop: '0px' }}>For a list of some algorithms and data structures I know, check out my distinctions & skills PDF:</p> */}

                                {/* <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '0px' }} className="dialoglinks" onClick={() => window.open("https://ishaanjavali.me/distinctions-and-skills")}> */}
                                {/* Distinctions and Skills PDF</button> */}
                                <br></br>
                                <b>I love competitive programming because it applies algorithms and data structures to challenging, thoughtprovoking problems in a timed, competitive environment while promoting
                                    both efficient coding and efficient code.</b>
                                <br></br>
                                <p>I've open-sourced my solutions to 100s of problems on GitHub:</p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/USACO-Solutions")}>
                                    100+ USACO Solutions</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/Codeforces-Solutions")}>
                                    200+ Codeforces Solutions</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/Google-Kick-Start-And-Code-Jam-Solutions")}>
                                    Google Kick Start & Code Jam</button>

                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={() => this.setFalse("AIME")} aria-labelledby="customized-dialog-title" open={this.state.AIME}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>AIME</h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2019 & 2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Qualified for the AIME in 9th and 11th grades.</p>
                                <p style={{ marginTop: '0px', fontSize: '17px' }}>Score of 8 (2021) is in top 10%.</p>

                                <p style={{ marginTop: '0px', fontSize: '17px' }}>I enjoy learning advanced concepts in my free time and competing
                                    in math competitions, both individually and with a team. I also enjoy proof-based contests like USAMTS and TAMU Power Team.</p>

                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={() => this.setFalse("TXSEF")} aria-labelledby="customized-dialog-title" open={this.state.TXSEF}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>TXSEF   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2017, 2020, 2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    Qualified for TXSEF in 7th, 10th, and 11th grades. <i>11th grade was the only year I did a partner project.</i> </p>
                                <p style={{ marginTop: '-10px', marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Placed 1st in category at TXSEF 2021 and got Honorable Mention for Best in the State of Texas in the Physical Sciences Division.
                                    <br></br>
                                    <br></br>
                                    In 10th grade I created a mobile sprinkler system designer, <b>E-rrigation</b> that provides estimates of water wastage for a user-created design. </p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '0px' }} className="dialoglinks" onClick={() => window.open("https://youtu.be/ZGEaRBm8hOk")}>
                                    E-rrigation Video (2 mins)</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks" onClick={() => window.open("https://play.google.com/store/apps/details?id=app.ij.errigation")}>
                                    E-rrigation App on Play Store</button>

                                <p>In 11th grade, my partner and I created a Global Landslide Analytics System.</p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '20px' }} className="dialoglinks" onClick={() => window.open("Abstract%20and%20Poster.pdf")}>
                                    Abstract and Poster</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks" onClick={() => window.open("https://ishaanjavali.me/Research%20Paper.pdf")}>
                                    Research Paper</button>
                                <p><u><b>Other Distinctions:</b></u></p>
                                <ul style={{ marginTop: '-15px' }}>
                                    <li><b>USAID Science for Development Award first place</b></li>
                                    <li><b>Presented at IEEE MIT URTC</b></li>
                                    <li><b>Paper being published in IEEE Xplore</b></li>
                                    <li>American Statistical Association Award</li>
                                    <li>Mu Alpha Theta Award</li>
                                </ul>
                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Click the buttons below to learn more about the project.</p> */}
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={() => this.setFalse("USAID")} aria-labelledby="customized-dialog-title" open={this.state.USAID}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>USAID   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    Won U.S. Agency for International Development Science for Development Award 1st place at ISEF ($5,000).</p>

                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '-7px' }} className="dialoglinks" onClick={() => window.open("https://www.usaid.gov/research/ISEF")}>
                                    USAID Article</button>

                                <p><i>See the ISEF card for more details on the project.</i></p>
                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Click the buttons below to learn more about the project.</p> */}
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog onClose={() => this.setFalse("Lockheed")} aria-labelledby="customized-dialog-title" open={this.state.Lockheed}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>Lockheed Martin CodeQuest  </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2018</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    Won 1st place with my team at Lockheed Martin CodeQuest when I was in 9th grade. I solved 7 or 8 out of the 11 or 12 problems my team solved.</p>

                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    Participating in Code Quest got me an internship at Lockheed in the summer of 2020.</p>

                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Click the buttons below to learn more about the project.</p> */}
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={() => this.setFalse("Dallas")} aria-labelledby="customized-dialog-title" open={this.state.Dallas}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>Dallas Data Challenge  </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2020</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    The city of Dallas hosted a Data Challenge for K-12 students. They provided some data sources and the goal was to analyze those sources or any available to
                                    answer a question of our choosing related to COVID.</p>

                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px' }}>
                                    I took it a step further and created Machine Learning models to forecast the number of available and occupied hospital beds in DFW, crucial for resource allocation. </p>

                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '-7px' }} className="dialoglinks" onClick={() => window.open("https://ishaanjavali.me/Ishaan%20Javali%20Dallas%20Data%20Challenge.ppsx")}>
                                    Presentation w/ Voiceover </button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks" onClick={() => window.open("https://github.com/ishaanjav/Dallas-Data-Challenge")}>
                                    GitHub w/ Code & Graphs </button>

                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Click the buttons below to learn more about the project.</p> */}
                            </div>
                        </DialogContent>
                    </Dialog>
                    {/* SCale  */}
                    <Dialog onClose={() => this.setFalse("SCALE")} aria-labelledby="customized-dialog-title" open={this.state.SCALE}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ fontSize: '28px', marginLeft: '16px', display: 'inline-block', float: 'left' }}>Scale AI's Generative AI Hackathon  </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>July 2023</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px' }}>
                                    Scale AI hosted their 2nd ever Gen AI Hackathon in San Francisco on July 15th. (it was a 1-day hackathon)</p>

                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px', marginTop: '0px' }}>
                                    We (my hackathon team & I) created <b>protex</b>, a web tool for researchers to search through novel & known proteins given natural-language descriptions of relevant terms/functions.
                                    {/* <br></br> */}
                                </p>

                                <p style={{ marginTop: '-5px', marginBottom: '35px' }}><i>How it works:</i> OpenAI's CLIP model embeds both protein descriptions (scraped from InterPro) and the protein sequence (embedded with Meta's ESM model). ChromaDB stores embeddings and a nearest-neighbor vector search finds the most relevant proteins for user queries. Site built with: React, NextJS, Tailwind CSS & Flask.</p>

                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '-7px' }} className="dialoglinks" onClick={() => window.open("https://youtu.be/m75GaY_1BoE")}>
                                    Video Demo </button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks" onClick={() => window.open("https://www.linkedin.com/posts/ishaanjavali_generativeai-aihackathon-artificialintelligence-activity-7086371228306833408-Z1Qq?utm_source=share&utm_medium=member_desktop")}>
                                    LinkedIn Post </button>

                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Click the buttons below to learn more about the project.</p> */}
                            </div>
                        </DialogContent>
                    </Dialog>
                    {/* COSCON */}
                    <Dialog onClose={() => this.setFalse("COSCON")} aria-labelledby="customized-dialog-title" open={this.state.COSCON}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ fontSize: '28px', marginLeft: '16px', display: 'inline-block', float: 'left' }}>Princeton COSCON</h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2023</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px' }}>
                                    Won 2nd place at Princeton's COSCON, a 1-day Computer Science contest covering problems in algorithms & data structures,
                                    math proofs, machine learning, cybersecurity, and even requiring some Assembly! </p>

                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px' }}>
                                    I handled the algorithms & data structures problems as well as machine learning. </p>
                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Click the buttons below to learn more about the project.</p> */}
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={() => this.setFalse("Crown")} aria-labelledby="customized-dialog-title" open={this.state.Crown}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>Crown Education Challenge  </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2020</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>
                                    An international challenge run by Harvard & Stanford students</p>

                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px', marginTop: '-1px' }}>
                                    When the pandemic hit, I designed and created <b>my app COVID-ID</b> in just 20 days, providing patients with instant notifications of their test results from doctors.
                                    <br></br> My goal was to help society return to normal by providing digital certification of their COVID status.</p>

                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '-7px' }} className="dialoglinks" onClick={() => window.open("https://ishaanjavali.me/projects")}>
                                    Brief Overview (PDF) </button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks" onClick={() => window.open("https://youtu.be/VlZ5EuwT1cI")}>
                                    Video Demo (2 mins)</button>

                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '18px', marginTop: '14px' }}>
                                    When I became a Crown Education Challenge Finalist, COVID-ID was supposed to be showcased in the <b>Harvard Technology Review</b>.</p>
                                {/* <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>Click the buttons below to learn more about the project.</p> */}
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={() => this.setFalse("KickStart")} aria-labelledby="customized-dialog-title" open={this.state.KickStart}>
                        <div style={{ display: 'inline-block' }}>
                            <h1 style={{ marginLeft: '16px', display: 'inline-block', float: 'left' }}>Google Kick Start   </h1>
                            <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px', float: 'right', color: '#444' }}>2021</p>
                        </div>
                        <DialogContent dividers>
                            <div style={{}}>
                                <p style={{ marginRight: '16px', display: 'inline-block', fontSize: '17px' }}>'Twas one silent night in March of 2021. I was reclining sleepily in my chair
                                    rewatching X-Men: First Class, my favorite X-Men film. The first round of Google Kick Start was starting at 11:00 PM so I decided what better way to kill some time than with "my fellow mutants"?
                                    <br></br>
                                    I'd just gotten to the part where Charles and Erik were playing the first of their iconic chess matches when I glanced at the time. It was 10:55. I paused the movie and sat up straight.
                                    Visual Studio Code became my canvas; my computer, my palette; my keystrokes, my brushstrokes. When the contest started I was ready to paint.
                                    <br></br>

                                    <br></br>
                                    Much to my amazement, 3 problems in I was ranked 13th on the international leaderboard alongside former IOI gold medallists.
                                    <br></br>
                                    I finished 157th out of over 19,800 contestants, the top 0.8%.
                                    <br></br>

                                    That was an unforgettable experience that left me excited, humbled, and motivated! Once the contest ended I returned to watching the movie, "mutant and proud".
                                    <br></br>
                                    <br></br>
                                    <b>I love competitive programming because it applies algorithms and data structures to challenging, thoughtprovoking problems in a timed, competitive environment while promoting
                                        both efficient coding and efficient code.</b>
                                </p>
                                {/* <br></br> */}

                                <p>I've open-sourced my solutions to 100s of problems on GitHub:</p>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/USACO-Solutions")}>
                                    100+ USACO Solutions</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/Codeforces-Solutions")}>
                                    200+ Codeforces Solutions</button>
                                <button style={{ fontWeight: '550', margin: '0 auto', display: 'block', marginTop: '10px' }} className="dialoglinks"
                                    onClick={() => window.open("https://github.com/ishaanjav/Google-Kick-Start-And-Code-Jam-Solutions")}>
                                    Google Kick Start & Code Jam</button>

                            </div>
                        </DialogContent>
                    </Dialog>
                    {/* <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
                        <h1>Tirle</h1>
                        <DialogContent dividers>
                            <h1>Test</h1>
                            <p style={{ fontSize: '25px', color: '#f00' }}>asdf</p>
                            <TTypography gutterBottom>
                                Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                                in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            </TTypography>
                            <TTypography gutterBottom>
                                Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
                                lacus vel augue laoreet rutrum faucibus dolor auctor.
                            </TTypography>
                            <TTypography gutterBottom>
                                Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
                                scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
                                auctor fringilla.
                            </TTypography>
                        </DialogContent>
                    </Dialog> */}


                    {/* <td><AwardRow name="" row={2}  text="Kiwanis Sophomore Scholarship" /></td> */}
                    {/* <div style={container}>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={0}  place="1st place" text="Dallas Data Challenge" />
                    </div>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={0}  place="USACO Gold" />
                    </div>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={0}  place="Finalist" text="Crown Education Challenge" />
                    </div>
                </div>
                <div style={{ ...container, marginTop: '50px' }}>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={1}   place="AIME" text="2x Qualifier" />
                    </div>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={1}   place="TXSEF" text="3x Qualifier" />
                    </div>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={1}  text="Kiwanis Sophomore Scholarship" />
                    </div>
                </div>
                <div style={{ ...container, marginTop: '50px' }}>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={2}   place="SJWP" text="Award (2020)" />
                    </div>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={2}   place="TAWWA" text="Award (2020)" />
                    </div>
                    <div style={{ lColumn }}>
                        <AwardRow name="" row={2}  place="Google"  text="Foobar Completed (Lvl 5)" />
                    </div>
                </div> */}
                    {/* <div style={{
                        borderRadius: '10px', background: 'rgba(255,255,255,1.65)', display: 'inline-block',
                        // marginRight: '10px', marginLeft: '10px',
                    }}>
                        <Pagination count={4} />
                        <Pagination count={4} color="standard" />
                        <Pagination count={4} color="secondary" />
                        <Pagination count={4} disabled />
                        <Pagination count={4} color="secondary" />

                        <Pagination count={4} variant="outlined" shape="rounded" color="secondary" />
                        <Pagination count={4} shape="rounded" color="secondary" />

                    </div>
                    <br></br>
                    <div style={{ display: 'inline-block', }}>
                        <Pagination count={4} />
                        <Pagination count={4} color="standard" />
                        <Pagination count={4} color="secondary" />
                        <Pagination count={4} disabled />
                        <Pagination count={4} color="secondary" />

                        <Pagination count={4} variant="outlined" shape="rounded" color="secondary" />
                        <Pagination count={4} shape="rounded" color="secondary" />

                    </div> */}
                </div>
            </MuiThemeProvider >)
    }
}
const divStyle = {
    width: '100%',
    textAlign: "center",
    paddingTop: spacing.sectionHeader,
    //CHANGED : this used to be 40px. i made it thicker for that bar styling design.
    // paddingBottom: '40px',
    paddingBottom: '51px',
    // background: colors.color3,
    // background: '#e0f3ff',
    background: '#47b0fe',
    // background: '#e6f5ff',
    // background: 'white',
    minHeight: '10em',
    display: 'table-cell',
    verticalAlign: 'middle'
}
const lColumn = {
    width: '33.3%',
    float: 'left',
    marginLeft: '200px'
}
const cColumn = {
    width: '33.3%',
    display: 'inline-block'
}
const rColumn = {
    width: '33.3%',
    float: 'right'
}
const container = {
    marginTop: '49px',
    // display: 'table'
    columnCount: '3',
    // columnGap: '3%'
    columnRuleWidth: '5%'
}


export default Awards
