import React, { Component } from 'react'
// import { colors, spacing } from '../constants/Constants.js'
import FadeIn from 'react-fade-in';
import AwardRowCards from './AwardRowCards.js'
var size = '4';
var colors = {
    'S': '#ea0000',
    'M': '#000',
    'C': '#15d149',
    'H': '#fd94ff',
    'O': '#ababab',
}
var fSize = '20px'
var tSize = '20px'
var min = '50%'
class AwardRow extends Component {
    constructor(props) {
        super(props);
        // if (props.page == "2")
        // min = "55%"
        // else min = "50%"
        // console.log(props.page)
        this.state = {
            width: min,
            ball: '22px'
        }
    }
    updateDimensions = () => {
        var w = window.innerWidth;
        var h = window.innerHeight;
        if (w < 1000) {
            this.setState({ width: '70%' })
        } else {
            this.setState({ width: "50%" })
        }
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        if (this.props.wid) {
            console.log("AI")
            this.setState({ width: this.props.wid })
        }
    }
    render() {
        return (
            // <AwardRowCards props={this.props} />
            <div style={{ width: '100%', textAlign: 'center' }} >
                <div className="card" style={{ ...divStyle, width: this.state.width, position: 'relative', cursor: 'pointer' }}
                    onClick={() => this.props.clicker(this.props.name)}
                //     function (e) {
                //     console.log("Click")
                //     this.props.clicker("TEST")
                // }}
                >
                    {/* <img src={this.props.image} style={imageStyles[this.props.row]}></img> */}
                    {/* <div style={{ display: 'inline-block', width: this.state.ball, height: this.state.ball, background: spanStyle[this.props.row].color, borderRadius: '8px' }}></div>
                    <p style={textStyle}>
                        <span style={spanStyle[this.props.row]}>{this.props.place}</span>
                        {(this.props.text && this.props.place) ? (((this.props.colon == "f")) ? " " : " ") : ""}
                        {this.props.text}
                    </p> */}


                    <p style={{
                        ...textStyle

                        , marginTop: this.props.top != undefined ? this.props.top : '9px'
                    }}>
                        <span style={{
                            ...spanStyle[this.props.row]
                            , fontSize: this.props.tsize != undefined ? this.props.tsize : spanStyle[this.props.row].fontSize
                        }}>{this.props.place}</span>
                    </p>
                    <p style={{ ...textStyle, marginTop: this.props.spacing != undefined ? this.props.spacing : '-9px', fontSize: this.props.size1 != undefined ? this.props.size1 : fSize }}>{this.props.text}</p>
                    <p style={{ ...textStyle, marginTop: '-16px', fontSize: this.props.size2 != undefined ? this.props.size2 : fSize }}>{this.props.below}</p>
                    <div style={{
                        borderRadius: '6px', position: 'absolute',
                        height: size + 'px', width: size + 'px', background: colors[this.props.t], marginBottom: '5px',
                        bottom: '0', display: 'inline-block',
                        // uncomment below 2 and comment 2 lines above if you want to have letters instead of circles.
                        // left: '0', marginBottom: '-11px', marginLeft: '12px'
                    }}>
                        {/* <p style={{ fontSize: '13px', color: colors[this.props.t] }}>{this.props.t}</p> */}
                    </div>
                </div>

            </div >
        )
    }
}
const imageStyle = {
    width: '42px',
    // background: '#c28719',
    padding: '10px',
    borderRadius: '14px',
    // filter: 'invert(1)',
    filter: 'invert(1)',
    absolution: 'relative',
    zIndex: '900'
}
const imageStyles = [
    {
        ...imageStyle,
        background: '#c28719',
    },
    {
        ...imageStyle,
        // filter: 'none'
        // background: '#fff',
        filter: 'invert(1)',
        background: '#e2b752',
    },
    {
        ...imageStyle,
        background: '#c28719',
    },
    {
        ...imageStyle,
        background: '#c28719',
    },
]

const textStyle = {
    fontSize: fSize,
    marginTop: '12px',
    // paddingBottom: '1px'
    // lineHeight:'1',
}
const spanStyleo =
{
    color: '#3676d0',
    fontSize: '23px',
}
const spanStyle = [
    {
        color: '#3f87eb',
        fontSize: '24px',
    },
    {
        color: '#e6a900',
        fontSize: '22px',
    },
    {
        color: '#e6a900',
        fontSize: '22px',
    },
]

const divStyle = {
    boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.22)',
    // boxShadow: '5px 10px 10px rgba(255, 255, 255, 0.5)',
    backgroundColor: 'white',
    // backgroundColor: '#f5f8fa',
    // backgroundColor: '#f5f8fa',
    // backgroundColor: '#f5f7fa',
    // border: '1px solid #dbe9ff',
    // border:'1px solid #3676d0',
    // backgroundColor: '#f7faff',
    padding: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '19px',
    marginBottom: '3px',
    // width: '75%',
    height: '14vh', // This is the one
    overflow: 'hidden',
    display: 'inline-block',
    // marginLeft: '13%',
}
export default AwardRow