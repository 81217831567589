import React, { Component } from 'react'
import NavigationBar from '../components/NavigationBar'
import EmptyBar from '../other/EmptyBar'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import About from '../components/About'
import Awards from '../components/Awards'
import Resume from '../components/Resume'
import Projects from '../components/Projects'
import Test from '../components/Test'
import { Document, Page } from 'react-pdf'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Resume from './Resume.pdf'
// import { CSSTransitionGroup } from 'react-transition-group'
// import PDFViewer from 'pdf-viewer-reactjs'
import { CustomView, isMobile, isMobileOnly, MobileOnlyView } from 'react-device-detect';
import MobileNavBar from '../components/MobileNavBar'
import Experience from '../components/Experience'
import Orig from '../components/Orig'
import Wave from '../backgrounds/Wave'
import Wave2 from '../backgrounds/Wave2'
import WaveIJ from '../backgrounds/WaveIJ'
import Wave3 from '../backgrounds/Wave3'
import ThinBar from '../other/ThinBar'

const array = ["a", "b", "c"];
function test(props) {
    return <p>Tasdfasdfasdfest</p>
}
/* 
TODO:
 1. Get PDF to show
*/
class Home extends Component {
    render() {
        return (

            <div style={{ background: '#fff', alignItems: 'center', ...divStyle }}>
                <Test />

                <MobileOnlyView>
                    <MobileNavBar home={true} />
                </MobileOnlyView>

                <CustomView condition={isMobileOnly === false}>
                    <NavigationBar home={true} />
                </CustomView>

                {/* <EmptyBar height='72px' /> */}
                {/*   <ReactCSSTransitionGroup
          transitionName="background"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
          >
        </ReactCSSTransitionGroup> */}

                {/*   <Document
          file={Resume}
          >
          <Page pageNumber={1} />
        </Document> */}

                {/* <div style={{ background: '#edf3f7', width: '100%', display: 'block' }}> */}
                <About />
                <EmptyBar height='15px' id="experience" />
                {/* <div style={{ marginTop: '10px', marginBottom: '-41px', width: '100%', height: '50px', background: 'linear-gradient(150deg, #fff, #efefef,#efefef', transform: 'rotate(1deg)' }}></div> */}

                {/* <div style={{ marginTop: '1px', width: '100%', height: '10px', background: 'linear-gradient(0deg, #efefef,#fff', }}></div> */}
                <Experience />

                {/* <p style={{ fontSize: '20px', textAlign: 'center', marginTop: '-10px' }}><i>More coming here....</i></p> */}
                {/* <EmptyBar height='73px' /> */}
                {/* <ThinBar height='1px' color='#bababa' /> */}
                {/* </div> */}
                {/* <p style={{ visibility: 'hidden' }}>a</p> */}
                {/* <p style={{ visibility: 'hidden' }}>a</p> */}
                {/* <p style={{ visibility: 'hidden' }}>a</p> */}
                {/* <p style={{ visibility: 'hidden' }}>a</p> */}

                {/* <p style={{ opacity: '0' }}> Test</p>
        <p style={{ opacity: '0' }}> Test</p>
        <p style={{ opacity: '0' }}> Test</p> */}
                {/* <div style={{ width: '100%', display: 'block' }}> */}
                {/* <Wave2 height='25vh' /> */}

                {/* <WaveIJ height='25vh' /> */}
                {/* <div style={{ marginTop: '-0px' }}>
                    <IJTop style={{ width: '100%' }} />
                    <IJ style={{ width: '100%' }} />
                </div> */}
                {/* <div style={{ marginTop: '-0px' }}>
                    <IJTop style={{ width: '100%', display: 'inline-block' }} />
                    <IJ style={{ width: '100%', display: 'inline-block' }} />
                </div> */}
                {/* <EmptyBar height='30px' /> */}
                <EmptyBar height='10px' id="ijapps" />
                <Orig style={{ width: '100%' }} />

                <Wave2 height='25vh' />
                <Awards style={{ width: '100vw' }} />
                <div style={{ marginTop: '-20px', width: '100%', height: '42px', background: 'linear-gradient(150deg, #47b0fe, #47b0fe, #fff', transform: 'rotate(1deg)' }}></div>
                <EmptyBar height='2px' id="projects" />
                <Projects style={{ width: '100vw' }} />

                {/* <div style={{ width: '100%', height: '50px', background: 'linear-gradient(90deg, #47b0fe, #fff7de', transform: 'rotate(-1deg)' }}></div> */}
                {/* <div style={{ marginTop: '-20px', width: '100%', height: '50px', background: 'linear-gradient(150deg, #47b0fe, #5cb9ff, #fff', transform: 'rotate(1deg)' }}></div> */}

                {/* <Wave height='25vh' /> */}

                {/* <EmptyBar height="77px" />
                <Projects />
                <EmptyBar height="110px" />
                <EmptyBar height="110px" />
                 */}

                {/* <Wave3 height='25vh' /> */}
                {/* background: 'linear-gradient(to right, #54aafe, #5cc8da)', */}

                {/* <h1 style={{ background: 'linear-gradient(to right, #54aafe, #5cc8da)',}}>asd</h1> */}
                {/* </div> */}
                {/* <EmptyBar height="110px" /> */}

                {/*  <p>Test</p>
        <test />
        {
          Array.apply(0, Array(30)).map(function (x, i) {
            return <p>Taa</p>
          })
        } */}
                {/* <div style={{ marginTop: '10px', marginBottom: '-19px', width: '100%', height: '50px', background: 'linear-gradient(150deg, #fff, #fff9e8, #fff7de', transform: 'rotate(-1deg)' }}></div> */}
                <div style={{ width: '100%', background: 'white' }}>
                    {/* <Resume /> */}
                    <Contact />
                    <Footer />
                </div>
            </div >

        )
    }
}
// Magic lines below. The top 3
const divStyle = {
    minHeight: '10em',
    display: 'table-cell',
    verticalAlign: 'middle',
    // display: 'flex', alignItems: 'center',
    // justifyContent: 'center'
}
export default Home