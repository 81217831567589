import { colors, spacing } from '../constants/Constants.js'

const particlesConfig = {
    particles: {
        color: {
            // value: ["#ffffff", '#fff']
            value: colors.particle
            // value:'#a6d5ff'
        }, opacity: {
            value: 1,
            anim: {
                enable: false, // Whether or not the dots fade in and out as they move
                // size_min: 3
            }
        },
        line_linked: {
            color: {
                value: "#ffffff"
            }
        },
        number: {
            // value: 6,
            // value: 16,
            value: 7,
            max: 20,
            // value: 35,
            // max: 60,
        },
        size: {
            value: 8,
            // random: true, // Do you want them to change in size.
            // min: 300,
            anim: {
                enable: true,
                speed: 3,
                size_min: 5
            }
        },
        move: {
            speed: 1.3,
            // speed: 1.6,
            outMode: 'out',
            "bounce": true,
        },
        line_linked: {
            width: 4,
            distance: 200,
            anim: {
                enable: true,
                size_min: 3
            }
        },
    }, interactivity: {
        detect_on: 'window',
        // detect_on: 'canvas',
        modes: {
            repulse: {
                distance: 300,
                duration: 0.2
            },
            push: {
                particles_nb: 2
            }
        },
        events: {
            onhover: {
                enable: true,
                // mode: "repulse"
            },
            onclick: {
                enable: true,
                mode: "push",
            },
            resize: true,

        }
    },

    /* "particles": {
        "number": {
            "value": 50,
            "density": {
                "enable": true,
                "value_area": 800
            }
        },
        "color": {
            "value": "#ffffff"
        },
        "shape": {
            "type": "circle",
            "stroke": {
                "width": 0,
                "color": "#000000"
            },
            "polygon": {
                "nb_sides": 5
            }
        },
        "opacity": {
            "value": 0.5,
            "random": false,
            "anim": {
                "enable": false,
                "speed": 1,
                "opacity_min": 0.1,
                "sync": false
            }
        },
        "size": {
            "value": 5,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 40,
                "size_min": 0.1,
                "sync": false
            }
        },
        "line_linked": {
            "enable": true,
            "distance": 175,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
        },
        "move": {
            "enable": true,
            "speed": 6,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
            }
        }
    },
    "interactivity": {
        "detect_on": "canvas",
        "events": {
            "onhover": {
                "enable": true,
                "mode": "grab"
            },
            "onclick": {
                "enable": true,
                "mode": "push"
            },
            "resize": true
        },
        "modes": {
            "grab": {
                "distance": 250,
                "line_linked": {
                    "opacity": 1
                }
            },
            "bubble": {
                "distance": 400,
                "size": 40,
                "duration": 2,
                "opacity": 8,
                "speed": 3
            },
            "repulse": {
                "distance": 150
            },
            "push": {
                "particles_nb": 1
            },
            "remove": {
                "particles_nb": 1
            }
        }
    },
    "retina_detect": true,
    "config_demo": {
        "hide_card": false,
        "background_color": "#b61924",
        "background_image": "",
        "background_position": "50% 50%",
        "background_repeat": "no-repeat",
        "background_size": "cover"
    } */
}

export { particlesConfig };