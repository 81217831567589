import React, { Component } from 'react'
import { colors, spacing } from '../constants/Constants.js'
import Collapsible from 'react-collapsible';
import Ishaan from '../images/meIJ.jpg'
import aysi from '../images/aysi.jpeg'
import justicecoin from '../images/justicecoin.jpeg'
import utd from '../images/utd.jpeg'
import skunk from '../images/skunk.png'
import lockheed from '../images/lockheed.png'
import pni from '../images/pni.png'
import ev3 from '../images/ev3.png'
import east from '../images/east.jpeg'
import ringle from '../images/ringle.png'
import gm from '../images/gm.png'
import golf from '../images/golf.jpeg'

import blob from '../backgrounds/blob.svg'
import dots from '../backgrounds/dots.svg'

import "../styles/Exp.css";
import EmptyBar from '../other/EmptyBar.js';

const tarS = '844', tarV = '175', tarH = '56';
var times = 0;
var curS = 0, curV = 0, curH = 0;
var duration = 2500; // how many seconds to animate for
var tots = 80; // how many updates to do 
var delay = 630; // how many seconds to wait

// Lockheeds
// top3Health
// JusticeCoin
// EV3 Everywhere
// AYSI Lecture Program VP
const space = '9px'
const space2 = space
var imageW = "53px";
var leftMarg = '12px';
var leftP = '32%';
var fSize = '19.2px';
var mgB = '10px'; // bottom of each card
var smaller = '18.5px';
// const divB = '#d6edff'

// comment out below divB and content of card to go back to original
// var back = true
var back = false
var divB = '#fff'
if (back)
    divB = '#efefef'
// const divB = '#efefef'
var card = {}
if (divB)
    card = {
        background: 'red',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '10px',
        paddingBottom: '-10px',
        borderRadius: '10px',
        boxShadow: 'rgba(120, 110, 111, 0.4) 0px 2px 9px 0px',
        // boxShadow: 'rgba(120, 110, 111, 0.4) 0px 2px 6px 0px',
        // boxShadow: '#ccd5dbee 0px 5px 12px 0px',
        // background: '#f5fbff',
        background: '#fff'
    }
const pB = '85px';
export class Experience extends Component {
    state = {
        top: '500'
    }

    render() {
        return (
            <div style={{
                ...divStyle,
                // marginTop: this.state.top + 'px', 
                backgroundImage: `url(${dots})`, backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'auto 91.3%',
                // backgroundSize: '76% auto'
            }} id="experienceScroll">

                <div style={{}}>
                    <p style={{
                        fontSize: '34px',
                        fontWeight: '750',
                        marginTop: '-10px',
                        // paddingTop: '10px',
                        textAlign: 'center',
                        color: 'black', userSelect: 'none', marginBottom: '0px'
                    }}>Experience</p>

                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <div style={{
                            display: 'table', flex: '0 1 520px'
                        }}>
                            <div style={{ display: 'inline-block', width: '100%' }}>
                                <p style={{ textAlign: 'center', ...sectionStyle }}>Work</p>
                            </div>
                            <div style={{
                                display: 'inline-block', marginBottom: '0px', width: '100%', ...card,
                            }}>
                                {/* GM */}
                                <img src={gm} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Software Engineer Intern</p>
                                    <p className="organization" onClick={() => window.open("https://www.gm.com")} style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>General Motors</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Jun - Aug, 2023 <b>·</b> 3 months</p>
                                </div>
                            </div>
                            {/* ringle */}
                            <div style={{ display: 'inline-block', marginTop: space, width: '100%', ...card }}>
                                <p style={{ borderRadius: '50%', float: 'left', width: imageW }}></p>
                                <div style={{ float: 'left', marginLeft: leftMarg, verticalAlign: 'middle' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Machine Learning Engineer (Part-Time)</p>
                                    <p className="organization" style={{ marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>Scan My Golf Ball</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Jul - Aug, Dec 2022 <b>·</b> 3 months</p>
                                </div>
                            </div>
                            <div style={{
                                display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card,
                            }}>
                                <img src={ringle} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>English Tutor</p>
                                    <p className="organization" onClick={() => window.open("https://www.ringleplus.com/en/student/landing/home")} style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>Ringle</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Jun - Aug, 2022 <b>·</b> 3 months</p>
                                </div>
                            </div>
                            <div style={{ display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card }}>
                                <img src={lockheed} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>AI / ML Engineer Intern</p>
                                    <p className="organization" onClick={() => window.open("https://www.lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics/skunkworks.html")} style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>Lockheed Martin | Advanced Development Programs</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Jun - Aug 2022 <b>·</b> 3 months</p>
                                </div>
                            </div>
                            <div style={{ display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card }}>
                                <img src={lockheed} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>AI / ML Engineer Intern</p>
                                    <p className="organization" onClick={() => window.open("https://www.lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics/skunkworks.html")} style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>Lockheed Martin | Advanced Development Programs</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Jun - Jul 2021 <b>·</b> 2 months</p>
                                </div>
                            </div>

                            <div style={{ display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card }}>
                                <img src={lockheed} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Software Engineer Intern (Backend)</p>
                                    <p onClick={() => window.open("https://www.lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics.html")} className="organization" style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>Lockheed Martin | Aeronautics</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Jun 2020 - Jul 2020 <b>·</b> 2 months</p>
                                </div>
                            </div>

                        </div>

                        {/* The left margin that appears for the second column when window is minimnzied is because of this 40 px */}
                        <div style={{
                            display: 'table', marginLeft: '40px'
                        }} > </div>

                        <div style={{
                            display: 'table', flex: '0 1 520px',
                        }}>
                            <div style={{ display: 'inline-block', width: '100%' }}>
                                <p style={{ textAlign: 'center', ...sectionStyle }}>Volunteering / Misc.</p>
                            </div>
                            {/* PNI */}
                            <div style={{ display: 'inline-block', width: '100%', ...card }}>
                                <img src={pni} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, verticalAlign: 'middle' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Undergraduate Researcher</p>
                                    <p onClick={() => window.open("https://pni.princeton.edu/")} className="organization" style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>Princeton Neuroscience Institute, Buschman Lab</p>
                                    {/* <p style={{ marginTop: '5px', fontSize: '17px', color:'#555', marginBottom:mgB }}>Aug 2021 - Present <b>·</b> 4 months</p> */}
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Feb - May 2023  <b>·</b> 4 months</p>
                                </div>
                            </div>
                            {/* AYSI */}
                            <div style={{ display: 'inline-block', marginTop: space, width: '100%', ...card }}>
                                <img src={aysi} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, verticalAlign: 'middle' }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Lecture Program VP & Mentor</p>
                                    <p onClick={() => window.open("https://aysi.org")} className="organization" style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>Association for Young Scientists & Innovators (AYSI)</p>
                                    {/* <p style={{ marginTop: '5px', fontSize: '17px', color:'#555', marginBottom:mgB }}>Aug 2021 - Present <b>·</b> 4 months</p> */}
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Oct 2020 - Feb 2024 <b>·</b> 3 years 4 months</p>
                                </div>
                            </div>
                            <div style={{ display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card }}>
                                <img src={ev3} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Robotics Mentor</p>
                                    <p className="organization" style={{ marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>EV3 Everywhere</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Aug - Dec 2020 <b>·</b> 5 months</p>
                                </div>
                            </div>

                            <div style={{ display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card }}>
                                <img src={justicecoin} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Desktop App Developer (ElectronJS)</p>
                                    <p onClick={() => window.open("https://justicecoin.org")} className="organization" style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>JusticeCoin</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Jun - Oct 2020 <b>·</b> 5 months</p>
                                </div>
                            </div>


                            <div style={{ display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card }}>
                                <img src={utd} width={imageW} style={{ ...imageStyle }} />
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Volunteer</p>
                                    <p className="organization" style={{ marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>UTD DARClab</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>Jul - Jul 2019 <b>·</b> 1 month</p>
                                </div>
                            </div>

                            <div style={{ display: 'inline-block', marginTop: space, marginBottom: '0px', width: '100%', ...card }}>
                                {/* <img width={imageW} style={{...imageStyle}} /> */}
                                <p style={{ borderRadius: '50%', float: 'left', width: imageW }}></p>
                                <div style={{ float: 'left', marginLeft: leftMarg, }}>
                                    <p style={{ fontWeight: '700', fontSize: fSize, marginTop: '2px', marginBottom: '0px' }}>Web Developer</p>
                                    <p className="organization" style={{ marginTop: '5px', fontSize: smaller, marginBottom: '0px' }}>top3Health</p>
                                    <p style={{ marginTop: '5px', fontSize: '17px', color: '#555', marginBottom: mgB }}>May - Jun 2019 <b>·</b> 3 months</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div >
        )
    }
}
const sectionStyle = {
    marginTop: '10px', fontSize: '25px', fontWeight: '700px', color: '#654d7d', marginBottom: '10px'
}
const btnStyle = {
    background: '#f76565', //3b8cff
    marginTop: '10px',
    borderRadius: '15px',
    // border: '5px solid ' + colors.border,
    border: '0px solid',
    padding: '15px',
    fontSize: '22px',
    fontWeight: '500',
    display: 'inline-block',
    color: '#fff',
    cursor: 'pointer'
}
const numbers = {
    fontSize: '33px',
    fontWeight: '600',
    marginTop: '0px',
    color: '#008cff', // The more indigo type of blue: 3779f5
    // fontFamily: 'Josefin Sans'
    fontFamily: 'Poppins'
}
const category = {
    fontSize: '25px',
    marginTop: '-30px',
    color: '#222',
    // color: '#4d75a3',
}
// I love you Google
// https://1linelayouts.glitch.me/
// https://youtu.be/qm0IfG1GyZU
const container = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
}
const container2 = {
    ...container,
    marginTop: '-21px',
}
const box = {
    // flex: '1 1 250px', /*  Stretching: */
    flex: '0 1 300px', /*  No stretching: */
    margin: '5px'
}
const box2 = {
    // flex: '1 1 250px', /*  Stretching: */
    flex: '0 1 260px', /*  No stretching: */
    margin: '40px',
}
// the span for the links in the IJ Apps descriptions text
const ijL = {
    textDecoration: 'underline',
    color: '#0091ff',
    cursor: 'pointer',
}

const divStyle = {
    width: '100vw',
    // textAlign: "center",
    paddingTop: '45px',
    // paddingTop: spacing.sectionHeader,
    // paddingBottom: "44px",
    paddingBottom: pB,
    // background: colors.color3,
    // background: '#e0f3ff',
    background: divB,
    // background: '#e6f5ff',
    // background: 'white',
    minHeight: '10em',
    display: 'table-cell',
    verticalAlign: 'middle',
    // marginTop: '-50px'

}
const imageStyle = {
    borderRadius: '50%',
    float: 'left'
}
export default Experience

// export default IJ
