import React, { Component } from 'react'
import { colors, spacing } from '../constants/Constants.js'
import FadeIn from 'react-fade-in';

class AwardRowCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: '47%',
        }
    }
    updateDimensions = () => {
        var w = window.innerWidth;
        var h = window.innerHeight;
        if (w < 925) {
            this.setState({ width: '70%' })
        } else {
            this.setState({ width: '47%' })
        }
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    render() {
        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <div style={{ ...divStyle, width: this.state.width }}>
                    {/* <img src={this.props.image} style={imageStyles[this.props.row]}></img> */}
                    {/* <p style={textStyle}>
                        <span style={spanStyle[this.props.row]}>{this.props.place}</span>
                        {(this.props.text && this.props.place) ? (((this.props.colon == "f")) ? " " : " ") : ""}
                        {this.props.text}
                    </p> */}


                    <p style={textStyle}>
                        <span style={spanStyle[this.props.row]}>{this.props.place}</span>
                    </p>
                    <p style={{ ...textStyle, marginTop: '-9px' }}>{this.props.text}</p>
                </div>

            </div>
        )
    }
}
const imageStyle = {
    width: '42px',
    // background: '#c28719',
    padding: '10px',
    borderRadius: '14px',
    // filter: 'invert(1)',
    filter: 'invert(1)',
    absolution: 'relative',
    zIndex: '900'
}
const imageStyles = [
    {
        ...imageStyle,
        background: '#c28719',
    },
    {
        ...imageStyle,
        // filter: 'none'
        // background: '#fff',
        filter: 'invert(1)',
        background: '#e2b752',
    },
    {
        ...imageStyle,
        background: '#c28719',
    },
    {
        ...imageStyle,
        background: '#c28719',
    },
]

const textStyle = {
    fontSize: '19px',
    marginTop: '12px',
    // paddingBottom: '1px'
    // lineHeight:'1',
}
const spanStyleo =
{
    color: '#3676d0',
    fontSize: '22px',
}
const spanStyle = [
    {
        color: '#3676d0',
        fontSize: '22px',
    },
    {
        color: '#e6a900',
        fontSize: '22px',
    },
    {
        color: '#e6a900',
        fontSize: '22px',
    },
]

const divStyle = {
    boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.1)',
    // backgroundColor: '#f5f8fa',
    // backgroundColor: '#f5f7fa',
    // border: '1px solid #dbe9ff',
    // border:'1px solid #3676d0',
    backgroundColor: '#f7faff',
    padding: '10px',
    paddingBottom: '5px',
    borderRadius: '20px',
    // width: '75%',
    // height: '15vh',
    display: 'inline-block',
    // marginLeft: '13%',
}
export default AwardRowCards
