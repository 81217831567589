// const blue = "#e0f3ff"
const blue = "#d6efff"
// d6efff
const red = "#ffe0e0"
// const redBg = 'rgb(228, 15, 15)'
const redBg = 'rgb(237, 66, 66)'
const redBord = 'rgb(255, 154, 154)'
const blueBg = 'rgb(8, 144, 255)'
const blueBord = 'rgb(154, 198, 255)'

// master mode for whether to use red theme or not
const useRed = false;
// do gradient color changes on landing page?
const changing = false;
// Are the hovers on the home page going to make the colors uniform or red for YT, etc
const uni = false;
// show typing animation on home
const showType = true;
// const useRed = true;
var colors = {
    color1: '#fff',
    color2: blue,
    color3: "#fff",
    color4: blue,
    border: blueBord,
    background: blueBg,
    contact: '#fff7de',
    award: '#3676d0',
    mode: 'blue',
    // particle: '#ffd7d4',
    particle: '#ffeebd',
    change: changing,
    uniform: uni,
};
if (useRed) {
    colors = {
        color1: '#fff',
        color2: red,
        color3: "#fff",
        color4: red,
        border: redBord,
        background: redBg,
        // border: 'rgb(255, 154, 154)',
        // color1: blue,
        // color2: "#fff",
        // color3: "#fff",
        // color4: blue,
        contact: '#fff7de',
        award: '#f54242',
        mode: 'red',
        particle: '#bfe3ff',
        change: changing,
        uniform: uni,
    };
}
const spacing = {
    sectionHeader: '35px', // awards and projects
    bottomHalf: '38px', // spacing for Resume and Contact Me
}

const links = {
    gmail: "mailto:ishaanjav@gmail.com",
    github: "https://github.com/ishaanjav",
    youtube: "https://www.youtube.com/ijapps",
    linkedin: "https://www.linkedin.com/in/ishaanjavali/",
}
const type = {
    type: showType
}
// published is a set of key-value pairs denoting whether elemnts should be shown if I will be sharing the link
//  so if I want to give people my web link, but not show Resume, I set status to true and conditional rendering
//  in the components will hide Resume.
const published = {
    status: false
}
export { colors, spacing, links, type, published };