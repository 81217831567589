import React from 'react'

function TitleOnly(title, clicker, image) {
    return (
        <div onClick={() => window.open(clicker)} style={{ cursor: 'pointer' }}>
            <img src={image} width="30px" height="auto" style={{
                display: "inline-block",
                marginRight: '10px',
                verticalAlign: 'middle'
            }}></img>
            <p style={{ display: "inline-block", fontWeight: '625', fontSize: '21px', verticalAlign: 'middle' }}>
                {title}
            </p>
        </div >
    )
}

function RegularPair(title, value, clicker, image) {
    return (
        <div onClick={() => window.open(clicker)} style={{ cursor: 'pointer' }}>
            <img src={image} width="30px" height="auto" style={{
                display: "inline-block",
                marginRight: '10px',
                verticalAlign: 'middle'
            }}></img>

            <p cursor="pointer" style={{
                display: "inline-block", fontWeight: '625', fontSize: '21px',
                verticalAlign: 'middle'
            }}>
                {title}:
                    <span style={{ fontWeight: '430', color: '#4d4d4d' }}> {value}</span> </p>
        </div>
    )
}
export default function ContactsText({ title, value, clicker, image }) {
    if (value) return RegularPair(title, value, clicker, image)
    else return TitleOnly(title, clicker, image)
}
