import React, { Component } from 'react'

export class RedirectorGen extends Component {
    componentDidMount() {
        console.log("loading PDF")
        var win = window.open(this.props.link, '_self');
        win.focus();
    }
    render() {
        return (
            <div>

            </div>
        )
    }
}

export default RedirectorGen
