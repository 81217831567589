import React, { Component } from 'react'
import ContactsText from '../text-items/ContactsText'
import github from '../images/github.png'
import linkedin from '../images/linkedin.png'
import youtube from '../images/youtube.png'
import gmail from '../images/gmail.png'
import { colors, spacing, links } from '../constants/Constants.js'
import { createPortal } from 'react-dom'

/* 
DONE (1/5/21): 
 1. Get icon images next to each of them
 2. Show hand on hover of the text and icons
*/

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            background: colors.contact,
        }
        // // we are using another color so make it transparent 
        // if (this.props.use) {
        //     this.setState({ background: 'rgba(0,0,0,0)' });
        //     console.log(this.state)
        //     console.log("DE")
        // }
        // this.setState({ background: 'rgba(0,0,0,0)' });
        // console.log(this.props.use)
        // console.log(this.state)
    }
    render() {
        return (
            <div id="contactScroll" style={{
                ...divStyle,
                // background: this.state.background
                background: this.props.use ? 'rgba(0,0,0,0)' : colors.contact
            }}>
                <p style={{
                    fontSize: '34px',
                    fontWeight: '750',
                    marginTop: '0px'
                }}>Contact Me</p>

                <div style={{ display: "inline-block", marginTop: '-15px' }}>
                    <p style={{ fontSize: '20px', color: "#4d4d4d", marginLeft: '10px', marginRight: '10px' }}>
                        Contact me through my email below. Check out my GitHub, YouTube Channel, and LinkedIn!
                    </p>
                    <div style={whiteBox}>
                        <div style={{ textAlign: "center", display: "inline-block" }}>
                            <div style={{ textAlign: "left" }}>
                                <ContactsText title="Email" value="ishaanjav@gmail.com"
                                    clicker={links.gmail}
                                    image={gmail} />
                                <ContactsText title="GitHub" value="ishaanjav"
                                    clicker={links.github}
                                    image={github} />
                                <ContactsText title="YouTube Channel" value="IJ Apps"
                                    clicker={links.youtube}
                                    image={youtube} />
                                <ContactsText title="LinkedIn"
                                    clicker={links.linkedin}
                                    image={linkedin} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const whiteBox = {
    background: "#fff",
    // width: "100%",
    borderRadius: '25px',
    marginTop: '30px',
    paddingTop: '17px',
    paddingBottom: '16px',
    border: '1px solid #065f9e',
    boxShadow: '2px 5px 8px #ccc',
    // marginBottom: '12px',
    marginBottom: '12px',
    marginLeft: '12px',
    marginRight: '12px',
    marginTop: '30px'
}

const divStyle = {
    width: "100%",
    // background: colors.contact,
    // background: "#fff3d1",
    textAlign: "center",
    // paddingTop: '48px',
    paddingTop: spacing.bottomHalf,
    paddingBottom: "44px",
}

export default Contact
