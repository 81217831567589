import React, { Component } from 'react'

export class Redirector extends Component {
    componentDidMount() {
        console.log("loading PDF")
        var win = window.open("https://ishaanjavali.me/Project%20Overviews,%20Ishaan%20Javali.pdf", '_self');
        win.focus();
    }
    render() {
        return (
            <div>

            </div>
        )
    }
}

export default Redirector
