import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { colors, spacing } from '../constants/Constants.js'

/* 
TODO:
 1. This is what you can do to display the Resume PDF embedded. 
    Create a page in the website that just displays the pdf so its like https://website/resume.pdf
    Then use some website embedding library to embed that link inside the Resume section and show that
    webpage in a little box. Try that.
*/
var interval = null;
class Resume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: '#ffffff',
            txt: '#000000'
        }
        this.enter = this.enter.bind(this);
        this.leave = this.leave.bind(this);
    }
    clicker() {
        //DONE : Download resume
        // OLD: not sure how to do it. Maybe when the website is released i have to put link?
        // var a = document.createElement("a");
        window.open("https://ishaanwebsite.web.app/resume.pdf", "_blank");
        // a.href = "https://ishaanwebsite.web.app/resume.pdf";
        // a.setAttribute("download", "Ishaan Javali Resume.pdf");
        // a.click();
    }
    enter() {
        console.log("ENTERED")
        var test = this;
        // test.setState({
        //     color: '#f00'
        // })
        clearInterval(interval);
        var times = 0;
        var before = this.state.color;
        let origA = parseInt(before.substring(1, 3), 16)
        let origB = parseInt(before.substring(3, 5), 16)
        let origC = parseInt(before.substring(5, 7), 16)
        var target = "#1a90f0"
        let tA = parseInt(target.substring(1, 3), 16)
        let tB = parseInt(target.substring(3, 5), 16)
        let tC = parseInt(target.substring(5, 7), 16)
        var freq = 10;
        var tot = 300;
        // stop updating the color (stop animation)
        this.setState({ txt: '#fff' })
        // setTimeout(
        //     function () {
        //         clearInterval(interval);
        //         console.log("cancelled timeout")
        //     }, tot);
        interval = setInterval(function () {
            times++;
            var fraction = times / (tot / freq * 5 / 6)
            var a = Math.floor(origA + fraction * (tA - origA));
            var b = Math.floor(origB + fraction * (tB - origB));
            var c = Math.floor(origC + fraction * (tC - origC));
            if (a <= tA || b <= tB || c <= tC) {
                a = tA; b = tB; c = tC;
            }
            // console.log(a + "  " + origA + " --> " + tA)
            var hexString = "#" + a.toString(16) + b.toString(16) + c.toString(16);
            test.setState({ color: hexString });
            // console.log("TEST");
            if (a == tA) {
                clearInterval(interval);
                return;
            }
        }, freq);
    }
    leave() {
        // console.log("LEFT entered")
        var test = this;
        clearInterval(interval);
        var times = 0;
        var before = this.state.color;
        let origA = parseInt(before.substring(1, 3), 16)
        let origB = parseInt(before.substring(3, 5), 16)
        let origC = parseInt(before.substring(5, 7), 16)
        var target = "#ffffff"
        let tA = parseInt(target.substring(1, 3), 16)
        let tB = parseInt(target.substring(3, 5), 16)
        let tC = parseInt(target.substring(5, 7), 16)
        var freq = 10;
        var tot = 300;
        // stop updating the color (stop animation)
        this.setState({ txt: '#000' })
        // setTimeout(
        //     function () {
        //         clearInterval(interval);
        //     }, tot);
        interval = setInterval(function () {
            times++;
            // var fraction = times / (tot / freq)
            var fraction = times / (30)
            var a = Math.floor(origA + fraction * (tA - origA));
            var b = Math.floor(origB + fraction * (tB - origB));
            var c = Math.floor(origC + fraction * (tC - origC));
            if (a >= 255 || b >= 255 || c >= 255) {
                a = 255; b = 255; c = 255;
            }
            // console.log(a + "  " + origA + " -> " + tA)
            var hexString = "#" + a.toString(16) + b.toString(16) + c.toString(16);
            test.setState({ color: hexString });
            // console.log("LEFT");
            // console.log("going")
            if (a == 255) {
                clearInterval(interval);
                return;
            }
        }, freq);
    }
    render() {
        return (
            <div id="resumeScroll" style={divStyle}>
                <p style={{
                    fontSize: '34px',
                    fontWeight: '750',
                    marginTop: '0px',
                }}>Resume</p>
                <p style={{ ...textStyle }}>View my resume by clicking on the button below.</p>
                <btn onMouseEnter={this.enter} onMouseLeave={this.leave} onClick={this.clicker} style={{ color: this.state.txt, background: this.state.color, ...btnStyle }}>View Resume</btn>
            </div>
        )
    }
}
const fix = {
    minHeight: '10em',
    display: 'table-cell',
    verticalAlign: 'middle',
    display: 'block',
    textAlign: 'center'
}
const btnStyle = {
    // background: '#fff',
    marginTop: '10px',
    borderRadius: '15px',
    border: '5px solid ' + colors.border,
    padding: '20px',
    fontSize: '25px',
    fontWeight: '500',
    display: 'inline-block',
    cursor: 'pointer'
}
const divStyle = {
    width: '100%',
    textAlign: "center",
    paddingTop: spacing.bottomHalf,
    paddingBottom: "40px",
    background: colors.color4,
    display: 'block',
}
const textStyle = {
    fontSize: '20px', display: 'block',
    marginLeft: '10px', marginRight: '10px'
}

export default Resume