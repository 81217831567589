import React, { Component } from 'react'
import { colors, spacing } from '../constants/Constants.js'
import handleViewport from 'react-in-viewport';
import "../styles/IJ.css";

const tarS = '3300', tarV = '700', tarH = '211';
var times = 0;
var curS = 0, curV = 0, curH = 0;
var duration = 3300; // how many seconds to animate for
var tots = 80; // how many updates to do 
var delay = 630; // how many seconds to wait

export class IJ extends Component {
    //pre render
    constructor(props) {
        super(props);
        // this.getEntry = this.getEntry.bind(this);
        this.state = {
            subs: '0',
            views: '0',
            hours: '0',
            // subs: 0,
            // views: tarV + "K+",
            // hours: tarH.substr(0, 1) + "." + tarH.substr(1, 2) + "K+",
        }
        this.getStyle = this.getStyle.bind(this);
        this.timerS = this.timerS.bind(this);
        this.enderS = this.enderS.bind(this);
        this.timerV = this.timerV.bind(this);
        this.enderV = this.enderV.bind(this);
        this.timerH = this.timerH.bind(this);
        this.enderH = this.enderH.bind(this);
    }
    /* getEntry = () => {
        const { inViewport, enterCount  }= this.props;
        if (inViewport && enterCount === 1)
            a++;
            console.log("In view first time " + a)
            // return { WebkitTransition: 'opacity 0.75s ease-in-out' };
        } else if (!inViewport && enterCount < 1) {
            // console.log("Not in view. Never been in view.")
        } else {
            // console.log("everything else")
        }
    } */
    // called post rendre
    // componentDidMount() {
    timerS() {
        curS += Math.trunc(Math.ceil(parseInt(tarS) / tots)); // 846 subs / 20 updates = increase by 42 each time
        if (curS > parseInt(tarS)) {
            console.log("DO: " + parseInt(tarS))
            curS = parseInt(tarS);
            this.setState({ subs: curS + '+' });
        } else
            this.setState({ subs: curS });
    }
    enderS() {
        curS = parseInt(tarS)
        this.setState({ subs: tarS + '+' });
    }
    timerV() {
        curV += Math.trunc(Math.ceil(parseInt(tarV) / tots));
        if (curV > parseInt(tarV)) curV = parseInt(tarV);
        this.setState({ views: curV + 'K+' });
    }
    enderV() {
        curV = parseInt(tarV)
        this.setState({ views: tarV + 'K+' });
    }
    timerH() {
        curH += Math.trunc(Math.round(parseInt(parseInt(tarH)) / tots));
        if (curH > parseInt(tarH)) curH = parseInt(tarH);
        if (curH < 100)
            this.setState({ hours: curH.toString().substr(0, 1) + "." + curH.toString().substr(1, 1) + "K+" });
        else
            this.setState({ hours: tarH.toString().substr(0, 2) + "." + tarH.toString().substr(2, 3) + "K+" });
    }
    enderH() {
        curH = parseInt(tarH)
        this.setState({ hours: tarH.toString().substr(0, 2) + "." + tarH.toString().substr(2, 3) + "K+" });
    }

    getStyle() {
        const { inViewport, enterCount } = this.props;

        // YOU CAN't USE this inside interval. Because interval's a class or something
        // that's why we use test
        var test = this;
        if (inViewport && enterCount === 1 && times === 0) {
            times++;
            console.log("In view first time " + times)

            // Delay before animation
            setTimeout(
                function () {
                    // how often we need to do updates.
                    var freq = Math.trunc(Math.ceil(duration / tots)); // 3000 ms / 50 updates = 60ms per update.

                    //FIX: They don't finish at same time. freq has to be adjusted
                    // updating the subscriber number
                    var intervalS = setInterval(test.timerS, freq - 9);
                    // updating the views number
                    var intervalV = setInterval(test.timerV, freq - 2);
                    // updating the hours number
                    var intervalH = setInterval(test.timerH, Math.round(freq * 0.92));
                    setTimeout(
                        function () {
                            clearInterval(intervalS);
                            clearInterval(intervalV);
                            clearInterval(intervalH);


                            // console.log("DONE: " + tarS);
                            test.enderS();
                            test.enderV();
                            test.enderH();

                            // test.setState({ subs: tarS });
                            // test.setState({ views: tarV });
                            // test.setState({ hours: tarH });
                        }, duration);
                }, delay);


            // const tarS = '846', tarV = '174', tarH = '56';
            // times += 1
            // console.log("In asdfsdfview first time " + times)
            // this.state = {
            //     // subs: '0',
            //     // views: '0',
            //     // hours: '0',
            //     subs: 110,
            //     views: tarV + "K+",
            //     hours: tarH.substr(0, 1) + "." + tarH.substr(1, 2) + "K+",
            // }
            // var interval = setInterval(function () {

            // }, 3);
            // var f = this.state;
            // setTimeout(
            //     function () {
            //         clearInterval(interval);
            //         f.subs = 500;
            //     }, 2000);
            // have a 0.25 second delay at first
            // var interval = setInterval(function () {
            //     curS += 1;
            //     // this.state = { 'a': curS };
            //     // this.state = {subs: curS}
            //     // this.setState({ subs: curS });
            //     console.log("TEST");
            // }, 3);
            // setTimeout(
            //     function () {
            //         clearInterval(interval);
            //         curS = tarS;
            //         this.setState({ subs: tarS })
            //     }, 3000);
            // return { WebkitTransition: 'opacity 0.75s ease-in-out' };
        } else if (!inViewport && enterCount < 1) {
            // console.log("Not in view. Never been in view.")
        } else {
            // console.log("everything else")
        }
    }

    render() {
        const { enterCount, leaveCount } = this.props;
        return (
            <div style={divStyle} id="ijScroll">

                <div style={this.getStyle()}>
                    <p style={{
                        fontSize: '34px',
                        fontWeight: '750',
                        // marginTop: '-45px', // before experiences
                        marginTop: '-28px',
                        color: 'black', userSelect: 'none'
                    }}>IJ Apps</p>
                    <p style={{
                        fontSize: '19px',
                        marginTop: '-10px',
                        color: 'black', userSelect: 'none'
                    }}><i>The one-stop shop for Android app development</i></p>
                    {/* <CountUp duration={5} end={100} >
                        {({ countUpRef }) => (
                            <div>
                                <span ref={countUpRef} />
                            </div>
                        )}
                    </CountUp> */}

                    <div class="parent white" style={container}>
                        <div class="box green" style={{ ...box }}>
                            <p style={{ ...numbers }}>{this.state.subs}</p>
                            <p style={{ ...category }}>subscribers</p>
                        </div>
                        <div class="box green" style={box}>
                            <p style={{ ...numbers }}>{this.state.views}</p>
                            <p style={{ ...category }}>views</p>
                        </div>
                        <div class="box green" style={box}>
                            <p style={{ ...numbers }}>{this.state.hours}</p>
                            <p style={{ ...category }}>hours watched</p>
                        </div>
                    </div>

                    <p style={{ fontSize: '18px', marginTop: '14px', marginBottom: '12px', color: '#000', marginLeft: '10px', marginRight: '10px', lineHeight: '1.5' }}>
                        I created IJ Apps the summer after 9th grade to share my love of Android app development through <span style={{ ...ijL }}
                            onClick={() => window.open("https://play.google.com/store/apps/developer?id=IJ+Apps")}>apps</span>, <span style={{ ...ijL }}
                                onClick={() => window.open("https://github.com/IJ-Apps")}>
                            GitHub projects</span>, and <span onClick={() => window.open("https://youtube.com/ijapps")} style={{ ...ijL }}>video tutorials</span>.
                        <br />
                        In the 4 years since, IJ Apps has transformed from a hobby into a platform for me to teach thousands.
                    </p>
                    <p style={{ fontSize: '24px', marginTop: '47px' }}>Explore my channel using the buttons below!</p>

                    <div class="parent white" style={container2}>

                        <button onMouseEnter={this.enter} onMouseLeave={this.leave} style={{ ...box2, fontWeight: '550' }} className="linkbtns" onClick={() => window.open("https://youtu.be/jhGm4KDafKU")}>
                            Trending Tutorial</button>

                        <button onMouseEnter={this.enter} onMouseLeave={this.leave} style={{ ...box2, fontWeight: '750' }} className="linkbtns" onClick={() => window.open("https://youtube.com/ijapps")}>
                            <b>YouTube Channel</b></button>

                        {/* <button onMouseEnter={this.enter} onMouseLeave={this.leave} style={{ fontWeight: '550' }} className="linkbtns" onClick={() => window.open("https://youtu.be/jhGm4KDafKU")}> */}
                        <button onMouseEnter={this.enter} onMouseLeave={this.leave} style={{ fontWeight: '550' }} className="linkbtns" onClick={() => window.open("https://youtu.be/yV9nrRIC_R0")}>
                            Latest Tutorial</button>
                    </div>
                </div>

            </div >
        )
    }
}
const btnStyle = {
    background: '#f76565', //3b8cff
    marginTop: '10px',
    borderRadius: '15px',
    // border: '5px solid ' + colors.border,
    border: '0px solid',
    padding: '15px',
    fontSize: '22px',
    fontWeight: '500',
    display: 'inline-block',
    color: '#fff',
    cursor: 'pointer'
}
const numbers = {
    fontSize: '33px',
    fontWeight: '600',
    marginTop: '0px',
    color: '#008cff', // The more indigo type of blue: 3779f5
    // fontFamily: 'Josefin Sans'
    fontFamily: 'Poppins'
}
const category = {
    fontSize: '25px',
    marginTop: '-30px',
    color: '#222',
    // color: '#4d75a3',
}
// I love you Google
// https://1linelayouts.glitch.me/
// https://youtu.be/qm0IfG1GyZU
const container = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
}
const container2 = {
    ...container,
    marginTop: '-21px',
}
const box = {
    // flex: '1 1 250px', /*  Stretching: */
    flex: '0 1 300px', /*  No stretching: */
    margin: '5px'
}
const box2 = {
    // flex: '1 1 250px', /*  Stretching: */
    flex: '0 1 260px', /*  No stretching: */
    margin: '40px',
}
// the span for the links in the IJ Apps descriptions text
const ijL = {
    textDecoration: 'underline',
    color: '#0091ff',
    cursor: 'pointer',
}

const divStyle = {
    width: '100vw',
    textAlign: "center",
    paddingTop: spacing.sectionHeader,
    // paddingBottom: "44px",
    paddingBottom: '40px',
    // background: colors.color3,
    // background: '#e0f3ff',
    background: '#fff',
    // background: '#e6f5ff',
    // background: 'white',
    minHeight: '10em',
    display: 'table-cell',
    verticalAlign: 'middle',
}
const MySection = handleViewport(IJ, { rootMargin: '-1.0px' });
export default MySection

// export default IJ
