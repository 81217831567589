import React, { Component } from 'react';
import axios from 'axios';
import { TextField, Button, IconButton } from '@material-ui/core';
// const functions = require('firebase-functions');
// const request = require('request-promise');
import SettingsIcon from '@material-ui/icons/Settings';
import { CustomView, isMobile, isMobileOnly, MobileOnlyView } from 'react-device-detect';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
const aaa = createMuiTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                color: 'white',
            },
        },
    }
});


class Helper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputText: '',
            canPress: true,
            bgcolor: 'black',
            isHer: false,
            checked: false,
            mode: 'normal',
            whoIsIt: false,
            language: "óúí, cést póssíblé!",

            history: [
                // { role: 'user', content: 'Hello' }
                // , { role: 'user', content: 'badfasdfasdfasdfasdf' }
                // , { role: 'assistant', content: 'bye' }
                // , { role: 'user', content: 'ssss' }
                // , { role: 'assistant', content: 'no' }
            ],
            open: false,
        };
        this.ref = React.createRef();
    }



    textToSpeech = async (inputText) => {
        // Set the API key for ElevenLabs API. 
        // Do not use directly. Use environment variables.
        const API_KEY = process.env.REACT_APP_ELEVEN_LABS;
        // Set the ID of the voice to be used.
        const VOICE_ID = process.env.REACT_APP_VOICE_ID

        // Set options for the API request.
        const options = {
            method: 'POST',
            url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
            headers: {
                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                'content-type': 'application/json', // Set the content type to application/json.
                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
            },
            data: {
                text: inputText, // Pass in the inputText as the text to be converted to speech.
                stability: 0.37,
                similarity_boost: 0.84, // Set the similarity boost to 0.5.
            },
            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
        };

        // Send the API request using Axios and wait for the response.
        const speechDetails = await axios.request(options);

        // Return the binary audio data received from the API response.
        return speechDetails.data;
    };

    getPromptEngineered = () => {
        const prompt = process.env.REACT_APP_TXT
        if (this.state.whoIsIt) return process.env.REACT_APP_TXTT
        return prompt;
    }

    getGPTResponse = async (inputText) => {
        const API_URL = "https://api.openai.com/v1/chat/completions";
        const API_KEY = process.env.REACT_APP_OPEN_AI;

        const messages = []
        const initial = {
            role: 'user', content: this.getPromptEngineered(),
        };
        messages.push(initial);
        let systemMessage = "";
        let contextLength = 12;
        for (let i = Math.max(0, this.state.history.length - contextLength);
            i < this.state.history.length; i++) {
            if (this.state.history[i].role === 'system')
                systemMessage = this.state.history[i];
            else
                messages.push(this.state.history[i]);
        }
        if (systemMessage !== "")
            messages.push(systemMessage);
        messages.push({ role: 'user', content: inputText });

        this.setState({ history: messages.slice(1) })
        // console.log(messages)
        const result = await fetch(API_URL, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`,
            },
            body: JSON.stringify({
                model: "gpt-3.5-turbo",
                messages: messages,
                max_tokens: 200,
            }),
        });
        if (this.ref.current) {
            this.ref.current.scrollTop = this.ref.current.scrollHeight;
        }
        const response = await result.json();
        // update title
        let title = response.choices[0].message.content;
        return title;
    }
    splitTextAtHalfWithSentences = (text) => {
        // Step 1: Split the text into an array of sentences based on sentence-ending punctuation marks.
        const sentences = text.match(/[^.!?]+[.!?]+/g);

        if (!sentences || sentences.length < 2) {
            // If there are less than two sentences, return the original text as the first part,
            // and an empty string as the second part (no splitting needed).
            return [text, ''];
        }

        // Step 2: Calculate the halfway index of the characters in the original text.
        const totalChars = text.length;
        const halfwayIndex = Math.ceil(totalChars * 0.55);

        // Step 3: Combine sentences until the halfway index is reached or slightly exceeded.
        let firstPart = '';
        let secondPart = '';
        let combinedLength = 0;

        for (const sentence of sentences) {
            // Check if adding the current sentence to the firstPart will exceed or equal the halfway index.
            if (combinedLength + sentence.length <= halfwayIndex) {
                firstPart += sentence;
                combinedLength += sentence.length;
            } else {
                // If it exceeds the halfway index, stop and put the rest in the secondPart.
                secondPart = text.slice(combinedLength);
                break;
            }
        }

        // Return both parts as an array of two elements.
        return [firstPart, secondPart];
    }

    handleClick = () => {
        // Perform some action with the message
        // console.log(this.state.message);
        // Clear the input box
        // this.setState({ message: '' });
        this.setState({ inputText: '' });
        this.setState({ canPress: false, bgcolor: 'grey' })
        const response = this.getGPTResponse(this.state.inputText).then((text) => {
            let newHistory = this.state.history;
            const [firstPart, secondPart] = this.splitTextAtHalfWithSentences(text);
            // console.log(text);
            if (text.length > 570 && secondPart.length > 2) {
                this.textToSpeech(firstPart).then((data) => {
                    newHistory.push({ role: 'assistant', content: text })
                    this.setState({ history: newHistory })
                    if (this.ref.current) {
                        this.ref.current.scrollTop = this.ref.current.scrollHeight;
                    }
                    // console.log(data);
                    this.setState({ canPress: true, bgcolor: 'black', cursorType: 'default' })
                    const audio = new Audio(URL.createObjectURL(new Blob([data], { type: 'audio/mpeg' })));
                    audio.playbackRate = 1.04
                    audio.play();
                    let audio2 = null;
                    this.textToSpeech(secondPart).then((data2) => {
                        audio2 = new Audio(URL.createObjectURL(new Blob([data2], { type: 'audio/mpeg' })));
                        audio2.playbackRate = 1.04
                    });
                    audio.addEventListener("ended", function () {
                        // console.log("ended first");
                        if (audio2 != null)
                            audio2.play();
                    });
                });
            } else {
                this.textToSpeech(text).then((data) => {
                    newHistory.push({ role: 'assistant', content: text })
                    this.setState({ history: newHistory })
                    if (this.ref.current) {
                        this.ref.current.scrollTop = this.ref.current.scrollHeight;
                    }
                    // console.log(data);
                    this.setState({ canPress: true, bgcolor: 'black', cursorType: 'default' })
                    const audio = new Audio(URL.createObjectURL(new Blob([data], { type: 'audio/mpeg' })));
                    audio.playbackRate = 1.035
                    audio.play();

                });
            }
        });

    }
    renderHistory = () => {
        return this.state.history.map((item, index) => {
            const { role, content } = item;
            const isAssistant = role === 'assistant';
            const textAlign = isAssistant ? 'left' : 'right';
            const fontSize = isMobile ? '15px' : '18px';
            const spacing = isMobile ? '20%' : '49%'
            const sideMargin = isMobile ? '12px' : '20px';
            const paddingMobile = isMobile ? '8px' : '10px';

            const commonStyle = {
                fontSize: fontSize, borderRadius: '10px', width: 'auto'
                , display: 'inline-block', padding: paddingMobile
            }
            if (index !== this.state.history.length - 1)
                commonStyle.marginBottom = '-5px';
            else
                commonStyle.marginBottom = '5px';

            if (isAssistant) {
                return (
                    <p key={item.id} style={{
                        ...commonStyle, marginLeft: sideMargin, marginRight: spacing,
                        background: '#ddd',
                    }}>
                        {content}
                    </p>
                );

            } else if (role === 'user') {
                return (
                    <div style={{ textAlign: 'right', }}>
                        <p key={item.id} style={{
                            ...commonStyle, marginRight: sideMargin,
                            marginLeft: spacing
                            , color: '#fff', background: '#cc8304'
                        }}>
                            {content}
                        </p>
                    </div>
                );
            }
        });

        // const historyItems = [];
        // for (let i = 0; i < history.length; i++) {
        //     const item = history[i];
        //     if (item.role === 'user') {
        //         historyItems.push(
        //             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '10px', width: '100%' }}>
        //                 <p style={{ background: '#fff', padding: '10px', borderRadius: '10px', maxWidth: '80%' }}>{item.content}</p>
        //             </div>
        //         )
        //     } else {
        //         historyItems.push(
        //             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '10px', width: '100%' }}>
        //                 <p style={{ background: '#fff', padding: '10px', borderRadius: '10px', maxWidth: '80%' }}>{item.content}</p>
        //             </div>
        //         )
        //     }
        // }
        // return historyItems;
    }

    handleModeSelection = (event) => {
        this.setState({ mode: event.target.value });
    }

    getDialog = () => {
        return (<Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
            <div style={{ background: 'black', display: 'flex', flexDirection: 'column', }}>
                <div style={{ background: 'linear-gradient(135deg, rgba(255, 202, 138, 0.23), rgba(255,255,255,0.2), rgba(199, 255, 200, 0.23))' }}>
                    <DialogTitle id="simple-dialog-title" style={{ color: 'white' }}><b>Think you can change me?</b></DialogTitle>
                    <FormControlLabel
                        control={<Checkbox
                            checked={this.state.checked}
                            onChange={() => this.setState({ checked: !this.state.checked })}
                            // inputProps={{ 'aria-label': 'primary checkbox' }}
                            style={{ color: 'red', marginLeft: '49px', marginTop: '-3px' }}
                            labelStyle={{ color: 'red' }}
                            color='primary'
                        />}
                        label={this.state.language}
                    />
                    <br />
                    <FormControl component="fieldset" style={{ textAlign: 'center', marginTop: '15px' }}>
                        <FormLabel component="legend" style={{ color: '#ccc', textAlign: 'center', marginLeft: '20px', marginTop: '16px', marginBottom: '3px' }}>Change my m̶o̶d̶e̶, I mean mood</FormLabel>
                        <RadioGroup aria-label="gender" value={this.state.mode} name="gender1" onChange={this.handleModeSelection} style={{ textAlign: 'center' }}>
                            <ThemeProvider theme={aaa}>
                                <FormControlLabel style={{ marginLeft: '50px' }} value="normal" control={<Radio />} label="Normal" />
                            </ThemeProvider>
                            <FormControlLabel style={{ marginLeft: '50px', }} value="sassy" control={<Radio />} label="😉" />
                            <FormControlLabel style={{ marginLeft: '50px', }} value="mode3" control={<Radio />} label="🤪" />
                            <FormControlLabel style={{ marginLeft: '50px', }} value="mode4" control={<Radio />} label="🥴" />
                            <FormControlLabel style={{ marginLeft: '50px', marginBottom: '13px' }} value="intellectual" control={<Radio />} label="🤓" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </Dialog>)
    }
    handleChange = (event) => {
        this.setState({ inputText: event.target.value });
    }
    handleClose = () => {
        //save everything
        let message = "";
        if (this.state.checked) {
            if (this.state.whoIsIt) {
                message = "You are to speak ONLY in Italian from here on out. Respond ONLY in Italian."
            } else {
                message = "You are to speak ONLY in French from here on out. Respond ONLY in French."
            }
        } else {
            message = "Go back to speaking in English."
        }
        if (this.state.mode === 'normal') {
            message += "Speak normally, exactly as Ishaan would. Pretend you are him."
        } else if (this.state.mode === 'sassy') {
            message += process.env.REACT_APP_sassy
            if (this.state.checked) message += process.env.REACT_APP_sassyextra
        } else if (this.state.mode === 'mode3') {
            message += process.env.REACT_APP_mode3
        } else if (this.state.mode === 'mode4') {
            message += process.env.REACT_APP_mode4
            if (this.state.checked) message += process.env.REACT_APP_mode4extra
        } else if (this.state.mode === 'intellectual') {
            if (this.state.whoIsIt)
                message += process.env.REACT_APP_intellectual2
            else
                message += process.env.REACT_APP_intellectual
        }
        let newHistory = [];
        for (let i = 0; i < this.state.history.length; i++) {
            if (this.state.history[i].role !== 'system')
                newHistory.push(this.state.history[i]);
        }
        newHistory.push({ role: 'system', content: message })
        this.setState({ open: false, history: newHistory });
    }

    launchDialog = () => {
        this.setState({ open: true });
    }
    handleNameChange = async (event) => {
        const name = event.target.value.toLowerCase();
        if (name !== process.env.REACT_APP_NAME && name !== process.env.REACT_APP_NAME2)
            return;
        if (name === process.env.REACT_APP_NAME2)
            this.setState({ whoIsIt: true, language: "si è possibile!" })

        this.setState({ isHer: true })

        const API_URL = "https://api.openai.com/v1/chat/completions";
        const API_KEY = process.env.REACT_APP_OPEN_AI;

        const messages = []
        let content1 = process.env.REACT_APP_TXT1;
        if (name === process.env.REACT_APP_NAME2) {
            content1 = process.env.REACT_APP_TXT1T;
        }
        const initial = {
            role: 'user', content: content1
        };
        messages.push(initial);

        const result = await fetch(API_URL, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`,
            },
            body: JSON.stringify({
                model: "gpt-3.5-turbo",
                messages: messages,
                max_tokens: 190,
            }),
        });
        const response = await result.json();
        // console.log(response);
        // update title
        let text = response.choices[0].message.content;
        let newHistory = this.state.history;
        newHistory.push({ role: 'assistant', content: text })
        this.setState({ history: newHistory })

        this.textToSpeech(text).then((data) => {
            // console.log(data);
            this.setState({ canPress: true, bgcolor: 'black' })
            const audio = new Audio(URL.createObjectURL(new Blob([data], { type: 'audio/mpeg' })));
            audio.playbackRate = 1.03
            audio.play();
        });
    }

    render() {
        let version = (
            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                height: '100vh', background: '#fff', backgroundImage: 'linear-gradient(45deg, #feffe8, white, #f8f2ff)'
            }}>
                {this.getDialog()}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px', width: '100%' }}>
                    <h1>Talk to Me</h1>
                </div>
                <div style={{ color: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-10px', width: '100%' }}>
                    <p><i>Enter some text and hear me talk!</i></p>
                </div>
                <div ref={this.ref} style={{
                    width: '70%', height: '90vh', overflowY: 'auto',
                    background: '#fff9ed', alignItems: 'center', justifyContent: 'center', borderRadius: '20px'
                    , border: '3px solid #ffde9c'
                }}>
                    {this.renderHistory()}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px', marginBottom: '10px', width: '100%' }}>
                    <TextField
                        multiline
                        rowsMax={4}
                        rows={1}
                        type="text"
                        value={this.state.inputText}
                        onChange={this.handleChange}
                        placeholder=" Enter a message"
                        style={{ marginRight: '10px', width: '40%' }}
                    />
                    <Button variant="contained" style={{ background: this.state.bgcolor }} onClick={this.handleClick}
                        disabled={!this.state.canPress}>
                        Enter
                    </Button>
                    <IconButton style={{ marginLeft: '10px', }} onClick={this.launchDialog}>
                        <SettingsIcon style={{ color: '#f59505' }} />
                    </IconButton>
                </div>
            </div>
        );
        if (isMobile) {
            version = (
                <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                    height: '100vh', background: '#fff', backgroundImage: 'linear-gradient(45deg, #feffe8, white, #f8f2ff)'
                }}>
                    {this.getDialog()}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px', width: '100%' }}>
                        <h1 style={{ fontSize: '29px' }}>Talk to Me</h1>
                    </div>
                    <div style={{ color: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-21px', width: '100%' }}>
                        <p><i>Enter some text and hear me talk!</i></p>
                    </div>
                    <div ref={this.ref} style={{
                        width: '95%', height: '90vh', overflowY: 'auto',
                        background: '#fff9ed', alignItems: 'center', justifyContent: 'center', borderRadius: '20px'
                        , border: '3px solid #ffde9c'
                    }}>
                        {this.renderHistory()}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px', marginBottom: '10px', width: '100%' }}>
                        <TextField
                            multiline
                            rowsMax={4}
                            rows={1}
                            type="text"
                            value={this.state.inputText}
                            onChange={this.handleChange}
                            placeholder=" Enter a message"
                            style={{ marginRight: '10px', width: '60%' }}
                        />
                        <Button variant="contained" style={{ background: this.state.bgcolor }} onClick={this.handleClick}
                            disabled={!this.state.canPress}>
                            Enter
                        </Button>
                        <IconButton style={{ marginLeft: '0px', }} onClick={this.launchDialog}>
                            <SettingsIcon style={{ color: '#f59505' }} />
                        </IconButton>
                    </div>


                </div>
            )
        }
        const mL = isMobileOnly ? '10px' : '0px';
        if (!this.state.isHer)
            return (
                <div style={{
                    width: '100vw', height: '100vh',
                    // backgroundImage: 'linear-gradient(135deg, rgb(255, 255, 255), #ffdedb)',
                }}>
                    <div style={{
                        width: '100vw', height: '100vh', display: 'flex',
                        backgroundImage: 'linear-gradient(45deg, rgba(251, 255, 184, 0.5), white, rgba(240, 227, 255, 0.9))',

                        // backgroundImage: `linear-gradient(135deg, #fff 0%, #fff4db 25%, transparent 25%, transparent 50%, #fff 50%, #fff4db 75%, transparent 75%, transparent 100%, #fff 50%)`
                        alignItems: 'center', justifyContent: 'center',
                    }}>
                        <div style={{}}>
                            <div style={{ marginLeft: mL }}>
                                {/* <h3>Turn up the volume on your device</h3> */}
                                <h3>Hi! Feel free to use it now on your phone too, if you want. Glad you like it!</h3>
                            </div>
                            <br />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <TextField
                                    multiline
                                    rowsMax={4}
                                    rows={1}
                                    type="text"
                                    // value={this.state.inputText}
                                    onChange={this.handleNameChange}
                                    placeholder="Who are you?"
                                    variant="outlined"
                                    style={{ alignItems: 'center', justifyContent: 'center', }}
                                /></div>
                        </div>
                    </div>
                </div>
            )
        else
            return (
                version
            );
    }
}

export default Helper;
