import React, { Component } from 'react'
import ij_circle from '../images/ij_circle.png'
import ijapps from '../images/ijapps.png'
import { Link } from 'react-scroll'
import { isMobile, isMobileOnly } from 'react-device-detect';


/* 
TODO
 1. Figure out how to keep text centered as scrolling
 2. Experiment with different colors for navigation bar when scrolling. Maybe gold instead of white looks nice.

LONG TERM
 1. Navigation Bar gets messed up when window is minimized a lot.
    (About goes to next line) Fix that
*/

const logo = ij_circle

// Just need to change origColor here to have whole thing changed.
// const origColor = '#fff1c7'
// const origColor = '#fff6d9'
// const origColor = '#d9f1ff'
const origColor = '#fff'
let origA = parseInt(origColor.substring(1, 3), 16)
let origB = parseInt(origColor.substring(3, 5), 16)
let origC = parseInt(origColor.substring(5, 7), 16)

class MobileNavBar extends Component {
    state = {
        // color: '#b5e1ff'
        // color: '#bfe6ff',
        color: origColor,
        initialHeight: '-1',
        height: '62px',
        dimens: '50px',
        margin: '10',
        bot: '0.3vh solid red',
        tcol: '#555',
        bot1: '0.3vh solid red',
        tcol1: '#555',
        bot2: '0.3vh solid red',
        tcol2: '#555',
        bot3: '0.3vh solid red',
        tcol3: '#555',
        bot4: '0.3vh solid red',
        tcol4: '#555',
    }

    listenScrollEvent = e => {
        // var offSet = 50;
        // var duration = 190;
        // before particles
        // var offSet = 15;
        // var duration = 140;
        if (!isMobileOnly) {
            var duration = 200;
            var offSet = window.innerHeight - duration - 55;
            // console.log(window.innerHeight);
            if (window.scrollY > offSet) {
                if (window.scrollY <= duration + offSet) {
                    // if (window.scrollY <= window.innerHeight) {
                    // Bar color
                    const fact = (window.scrollY - offSet) / (duration + 10);
                    // let a = 181, b = 225, c = 255;
                    let a = origA, b = origB, c = origC;
                    a = a + (255 - a) * fact;
                    b = b + (255 - b) * fact;
                    c = c + (255 - c) * fact;
                    // console.log(a + " " + origA)
                    var amt = 1 - fact * 0.1; // decrease the decimal to make it more opaque in the end
                    // var res = "rgb(" + a + ", " + b + ", " + c + ")";
                    // var res = "rgba(" + a + ", " + b + ", " + c + "," + amt + ")";
                    var res = "rgb(" + a + ", " + b + ", " + c + ")";
                    this.setState({ color: res })

                    // Bar height
                    const newHeight = 62 - (62 - 48) * (window.scrollY - offSet) / duration;
                    this.setState({ height: newHeight + 'px' })

                    // Image dimens
                    const newDimens = 50 - (50 - 35) * (window.scrollY - offSet) / duration;
                    this.setState({ topMargin: -2, dimens: newDimens + 'px' })
                } else if (window.scrollY > window.innerHeight) {
                    // this.setState({ color: '#fff4c9' })
                    this.setState({ color: '#fffffff0' })
                } else {
                    this.setState({ color: '#ffffff' })
                }

            } else {
                this.setState({ topMargin: 0, dimens: '50px' })
                this.setState({ height: 62, color: origColor })
            }
        }
        // this.setState({ margin: '-10' });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
        const height = document.getElementById('container').clientHeight;
        this.setState({ initialHeight: height })
        console.log("Intial Height: ", height)
        console.log("Text Height: ", document.getElementById('contact').clientHeight)
    }
    constructor(props) {
        super(props);
        this.enter = this.enter.bind(this);
        this.leave = this.leave.bind(this);
        this.enter1 = this.enter1.bind(this);
        this.leave1 = this.leave1.bind(this);
        this.enter2 = this.enter2.bind(this);
        this.leave2 = this.leave2.bind(this);
        this.enter3 = this.enter3.bind(this);
        this.leave3 = this.leave3.bind(this);
        this.enter4 = this.enter4.bind(this);
        this.leave4 = this.leave4.bind(this);
    }
    enter() {
        this.setState({ bot: '0.35vh solid #5ea6ff' })
        this.setState({ tcol: '#000' })
    }
    leave() {
        this.setState({ bot: '0.3vh solid red' })
        this.setState({ tcol: '#555' })
    }
    enter1() {
        this.setState({ bot1: '0.35vh solid #5ea6ff' })
        this.setState({ tcol1: '#000' })
    }
    leave1() {
        this.setState({ bot1: '0.3vh solid red' })
        this.setState({ tcol1: '#555' })
    }
    enter2() {
        this.setState({ bot2: '0.35vh solid #5ea6ff' })
        this.setState({ tcol2: '#000' })
    }
    leave2() {
        this.setState({ bot2: '0.3vh solid red' })
        this.setState({ tcol2: '#555' })
    }
    enter3() {
        this.setState({ bot3: '0.35vh solid #5ea6ff' })
        this.setState({ tcol3: '#000' })
    }
    leave3() {
        this.setState({ bot3: '0.3vh solid red' })
        this.setState({ tcol3: '#555' })
    }
    enter4() {
        this.setState({ bot4: '0.35vh solid #5ea6ff' })
        this.setState({ tcol4: '#000' })
    }
    leave4() {
        this.setState({ bot4: '0.3vh solid red' })
        this.setState({ tcol4: '#555' })
    }
    render() {
        return (
            <div id="container" style={{ ...divStyle, background: this.state.color, height: this.state.height, verticalAlign: 'center' }} >
                <img onClick={() => window.open("https://youtube.com/ijapps")} src={logo} style={{
                    height: this.state.dimens,
                    width: this.state.dimens,
                    marginLeft: '3%',
                    verticalAlign: 'top',
                    marginTop: '7px',
                    cursor: 'pointer'
                }}></img>
                <h3 style={{
                    display: 'inline-block',
                    overflow: 'hidden', whiteSpace: 'nowrap',
                    marginLeft: '10px', color: '#065f9e', cursor: 'pointer'
                }}>
                    <Link to="aboutScroll" spy={true} smooth={true} offset={-45}>Ishaan Javali</Link>
                </h3>
                <div style={{ width: '70%', float: 'right' }}>
                    <h4 onMouseEnter={this.enter} onMouseLeave={this.leave} id="contact"
                        style={{ ...textStyle, marginRight: '5%', borderBottom: this.state.bot, color: this.state.tcol }}>
                        <Link to="contactScroll" spy={true} smooth={true} offset={-41} >Contact</Link>
                    </h4>
                    <h4 onMouseEnter={this.enter1} onMouseLeave={this.leave1}
                        style={{ ...textStyle, borderBottom: this.state.bot1, color: this.state.tcol1 }}>
                        <Link to="resumeScroll" spy={true} smooth={true} offset={-41}>Resume</Link>
                    </h4>
                    <h4 onMouseEnter={this.enter2} onMouseLeave={this.leave2}
                        style={{ ...textStyle, borderBottom: this.state.bot2, color: this.state.tcol2 }}>
                        <Link to="awardsScroll" spy={true} smooth={true} offset={-41}>Awards</Link>
                    </h4>
                    <h4 onMouseEnter={this.enter3} onMouseLeave={this.leave3}
                        style={{ ...textStyle, borderBottom: this.state.bot3, color: this.state.tcol3 }}>
                        <Link to="projectsScroll" spy={true} smooth={true} offset={-41}>Projects</Link>
                    </h4>
                    <h4 onMouseEnter={this.enter4} onMouseLeave={this.leave4}
                        style={{ ...textStyle, marginLeft: '0%', borderBottom: this.state.bot4, color: this.state.tcol4 }}>
                        <Link to="aboutScroll" spy={true} smooth={true} offset={-38}>About Me</Link>
                    </h4>

                </div>
            </ div >
        )
    }
}

const textStyle = {
    // display: 'inline-block',
    marginLeft: '5%',
    float: 'right',
    verticalAlign: 'middle',
    // hover
    cursor: 'pointer',
    fontSize: '15px',
    // borderBottom: '0.3vh solid red'
    // fontFamily: 'Poppins'
    // background: '#f00', padding: '10px', marginTop: '9px'
}

const divStyle = {
    top: 0,
    position: 'fixed',
    background: '#b5e1ff',
    width: '100%',
    // display: 'flex',
    // boxShadow: '1px 1px 1px #ccc',
    // borderBottom: '1px solid #5ea6ff',
    zIndex: '990',
    marginTop: '0px',
    // filter: 'blur(10px)'
}



export default MobileNavBar
