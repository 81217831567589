import React, { Component } from 'react'

export class Redirector2 extends Component {
    componentDidMount() {
        console.log("loading PDF")
        // old one. replaced 9.10.22
        // var win = window.open("https://ishaanjavali.me/Distinctions%20and%20Skills,%20Ishaan%20Javali.pdf", '_self');
        var win = window.open("https://ishaanjavali.me/Ishaan%20Javali%20Honors%20and%20Awards.pdf", '_self');
        win.focus();
    }
    render() {
        return (
            <div>

            </div>
        )
    }
}

export default Redirector2
