import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import Ishaan from '../images/Ishaan.jpg'
import "../styles/About.css"
import { colors } from '../constants/Constants.js'
import { CustomView, isMobile, isMobileOnly, MobileOnlyView } from 'react-device-detect';

const title = "Hello!";
const line1 = "I'm Ishaan Javali";
const line2 = "I'm an avid programmer with interests in machine learning, app development, and algorithms & data structures.";
// const line3 = "I've worked on numerous projects: an emotion analyzer app, smart sprinkler system designer, landslide forecasting system that exceeds existing methods, information system for dementia patients and their caretakers, and more!";
const line3 = "I've worked on numerous projects including:";
const line4 = "I love expanding my knowledge through projects and competing in competitions.";
/*
Hello there!
I'm Ishaan Javali.
I'm an avid programmer with interests in machine learning, app development, and algorithms & data structures.

I've worked on numerous projects: a facial recognition system, emotion analyzer app, smart sprinkler system designer,
information system for dementia patients and their caretakers, and more!
I love expanding my knowledge through projects and competing in competitions.

My work experience includes 2 internships at Lockheed Martin as a Hana Developer (2020) and a ____ (2021) as well as
a role as a web developer in a startup (2019).
*/
var marginV = 0;
class About extends Component {
    // state = {
    //     margin: '0px'
    // }
    move() {
        console.log("HI")
        var interval = setInterval(function () {
            this.setState({ margin: marginV + 100 });
            marginV += 100;
            console.log("TEST");
        }, 1000);
        setTimeout(
            function () {
                clearInterval(interval);
            }, 5000);
    }
    tester() {
        console.log("AS");
    }
    constructor(props) {
        super(props);
        // aboutW is width for about box with description. Leave it blank unless its for mobile, then set to 50%
        this.state = {
            margin: '0',
            top: '0px',
            aboutW: '',
        }
        // this.handleClick = this.handleClick.bind(this);
        // this.loadArticle = this.loadArticle.bind(this);
        this.move = this.move.bind(this);
        this.img = React.createRef()
    }
    listenScrollEvent = e => {
        // var offSet = 50;
        // var duration = 190;
        // before particles
        // var offSet = 15;
        // var duration = 140;
        // Fix this to get location of image in viewport
        /*   console.log(ReactDOM
              .findDOMNode(this.refs['img'])
              .getBoundingClientRect()); */
        // console.log(window.innerHeight);
        // console.log("y: ", this.img.current.offsetTop)
        // var top = Math.ceil(ReactDOM
        //     .findDOMNode(this.refs['img'])
        //     .getBoundingClientRect().top);

        // console.log(top);   
        /* if(this.prev > window.scrollY){
            console.log("up")
        }else{
            console.log("Down")
        } */

        // Only do scroll animation if it is a tablet
        //TODO : May want to consider doing animation regardless of device, based on height of component
        //   and height of screen and if component does not fit on screen, then do animation
        if (isMobile && !isMobileOnly) {
            var up = this.prev > window.scrollY;
            var top = Math.ceil(window.scrollY)
            console.log(top);
            if (!up && window.scrollY < 1250 && window.scrollY > 920) {
                this.setState({ top: (top - 920) + 'px' });
            } else if (up && window.scrollY < 700 && window.scrollY > 365) {
                this.setState({ top: (top - 380) + 'px' });
            }
            this.prev = window.scrollY;
            this.setState({ aboutW: '57%' })
        }
        // this.setState({ margin: '-10' });
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
        this.prev = window.scrollY
        // console.log("HI")
        // this.tester();
        // this.move.bind(this);
        // this.move();
        // this.move();
    }
    render() {
        // var topSpace = '24px'; //original
        var topSpace = '35px';
        var bottomSpace = '68px';
        if (this.props.top) topSpace = this.props.top;
        if (this.props.bottom) bottomSpace = this.props.bottom;
        return (
            <div id="aboutScroll" style={{
                display: 'flex', background: colors.color1, width: '100%', marginLeft: this.state.margin,
                paddingBottom: bottomSpace,
                // This is the margin from the particles
                paddingTop: topSpace,
                zIndex: 100, position: 'relative'
            }}>
                <div id="horizontal" style={{ zIndex: 100, position: 'relative', background: colors.color1, width: '100%' }}>
                    {/* For non-mobile devices */}
                    <CustomView condition={isMobileOnly === false}>
                        <div style={{ width: '40%', float: 'left', zIndex: 100, position: 'relative' }}>
                            <h1 style={titleStyle}>{title}</h1>
                            <img ref='img' id="me"
                                src={Ishaan} style={{
                                    marginTop: this.state.top,
                                    background: colors.border,
                                    // position: 'absolute',
                                }} >
                            </img>
                        </div>
                        <div id="textDiv" style={{ marginTop: '51px', width: this.state.aboutW, zIndex: 100, position: 'relative', background: colors.background, border: '6px solid ' + colors.border, }}>
                            <h1 style={{ marginLeft: '10%', color: 'white' }} id="line1" > {line1}</h1>
                            <p class="aboutT" >I'm an avid programmer with interests in
                                <span> machine learning</span>,
                                <span> app development</span>, and
                                <span> data structures & algorithms</span>.</p>
                            {/* <p class="aboutT" >{line3}</p> */}
                            <p class="aboutT" >I love expanding my knowledge by working on projects including: </p>
                            <ul style={ulStyle}>
                                {/* <li>Face Analyzer App</li> */}
                                <li>Global Landslide Analytics System <span style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: '550', color: '#ffe3de' }} onClick={() => window.open("https://ishaanjavali.me/research")}><i>(Published paper)</i></span></li>
                                {/* <li>Program for joining your Zoom meetings for you</li> */}
                                <li>Semantic search for proteins based off desired function. <span onClick={() => window.open("https://www.linkedin.com/posts/ishaanjavali_generativeai-aihackathon-artificialintelligence-activity-7086371228306833408-Z1Qq?utm_source=share&utm_medium=member_desktop")}
                                    style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: '550', color: '#ffe3de' }}><i> (Scale AI Hackathon 2nd place</i></span>)</li>
                                <li>Image Classification App with Teachable Machine <span style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: '550', color: '#ffe3de' }} onClick={() => window.open("https://github.com/IJ-Apps/Image-Classification-App-with-Teachable-Machine")}><i> (70+ stars on GitHub</i></span>)</li>
                                <li>Information system for dementia patients and their caretakers</li>
                                {/* <li>Global Landslide Analytics System</li>
                                <li>Face Analyzer app</li>
                                <li>Information system for dementia patients and their caretakers</li> */}
                            </ul>
                            {/* <p class="aboutT" style={{ marginTop: '-10px' }} >When I'm not making things, I enjoy playing soccer, watching movies and shows (especially Marvel), listening to Michael Giacchino's work, and reading Google News. </p> */}
                            <p class="aboutT" style={{ marginTop: '-10px' }} >When I'm not making things, I enjoy playing soccer & basketball, watching movies & shows (esp. Marvel), listening to Zimmer & Djawadi's music, and reading AI/tech news. </p>

                            <p class="aboutT" style={{ marginTop: '27px', textAlign: 'center', fontSize: '22px', marginBottom: '30px' }}>
                                <span>Featured on this site are my projects and awards.</span>
                                {/* <span>On this site, you can learn about my projects and awards.</span> */}
                            </p>
                            <ul style={ulStyle}>
                            </ul>
                            {/*  <h1 style={{ marginLeft: '10%', color: 'white' }} id="line1" > {line1}</h1>
                            <p class="aboutT" >I'm an avid programmer with interests in
                                <span> machine learning</span>,
                                <span> app development</span>, and
                                <span> data structures & algorithms</span>.</p>
                            <p class="aboutT" >I love expanding my knowledge by competing, and working on projects including: </p>
                            <ul style={ulStyle}>
                                <li>Landslide Forecasting System</li>
                                <li>Smart Sprinkler System Designer</li>
                                <li>Information system for dementia patients and their caretakers</li>
                            </ul>
                            <p class="aboutT" style={{ marginTop: '-12px' }}>Interests & Hobbies: </p>
                            <ul style={ulStyle}>
                                <li>Playing the cello (I'm self-taught)</li>
                                <li>Marvel (Watched all the movies and shows in the 2000s)</li>
                                <li>Basketball and soccer</li>
                            </ul> */}
                            {/* <p class="aboutT" style={{ marginTop: '-15px' }}>{line4}</p> */}
                            {/* <p class="aboutT" style={{ marginTop: '-10px', fontWeight: '500' }}>
                                My educational YouTube channel <span id="red">IJ Apps</span>, for teaching app development, has
                                <span id="red"> 850+ subscribers</span> and over
                                <span id="red"> 170K views.</span>
                            </p>
                            <p class="aboutT" style={{ marginTop: '-7px' }}>My work experience includes 2 internships at Lockheed Martin as an <span id="green">AI Engineer</span> (2021) and a <span id="green">Hana Developer</span> (2020)
                                {/* and a web developer in a startup (2019)*/}

                        </div>
                    </CustomView>

                    {/* For phones only */}
                    <CustomView condition={isMobileOnly === true}>
                        <div style={{
                            zIndex: 100,
                            background: 'white', border: '6px solid ' + colors.border,
                            marginLeft: '7px', float: '', marginTop: '41px',
                            borderRadius: '30px 0px 0px 30px', marginRight: '3px',
                            // alignItems: 'center', justifyContent: 'center',
                            // README Magic trick: if you uncomment display:'flex' it shows the laptop view on mobile
                            // display: 'flex'
                            // textAlign:'center',
                        }}>
                            <div style={{ width: '100%' }}>
                                <h1 style={{ marginLeft: '9%', color: '#000' }} id="line1" > Hello!</h1>
                                <img ref='img' id="me"
                                    src={Ishaan} style={{
                                        marginTop: this.state.top,
                                        background: colors.border,
                                        align: 'center',
                                        width: '40vmin'
                                        // position: 'absolute',
                                    }} >
                                </img>
                            </div>
                            <p class="aboutT" style={{ marginLeft: '16px', color: '#000' }}>I'm an avid programmer with interests in
                                <span style={{ color: '#3676d0' }}> machine learning</span>,
                                <span style={{ color: '#3676d0' }}> app development</span>, and
                                <span style={{ color: '#3676d0' }}> data structures & algorithms</span>.</p>
                            <p class="aboutT" style={{ marginLeft: '16px', marginTop: '-5px', color: '#000', fontWeight: '400' }}>{line4}</p>
                            <button style={{
                                borderRadius: '10px',
                                // marginLeft: '20px', marginRight: '20px',
                                margin: '0 auto', display: 'block',
                                fontSize: '22px', background: '#d6edff', border: '3px solid #1a90f0',
                                padding: '10px'
                            }} onClick={() => window.open("https://github.com/ishaanjav")}> View Projects</button>
                            <p class="aboutT" style={{ marginLeft: '16px', marginTop: '10px', fontWeight: '400', color: '#000' }}>
                                My educational YouTube channel <span style={{ fontWeight: '710', color: '#ff7373' }}>IJ Apps</span>, for teaching app development, has
                                <span style={{ fontWeight: '710', color: '#ff7373' }}> 600+ subscribers</span> and over
                                <span style={{ fontWeight: '710', color: '#ff7373' }}> 100K views.</span>
                            </p>
                            <p class="aboutT" style={{ marginLeft: '16px', color: '#000', fontWeight: '405' }} >
                                My work experience includes 2 internships at Lockheed Martin as an <span style={{ fontWeight: '710', color: '#f7bd00' }}>AI Engineer</span> (2021), and a <span style={{ fontWeight: '710', color: '#f7bd00' }}>Hana Developer</span> (2020)
                                and a web developer in a startup (2019).</p>
                        </div>
                    </CustomView>
                </div >

            </div >
        )
    }
}

const ulStyle = {
    color: 'white',
    marginLeft: '4%',
    // fontFamily: 'Nunito',
    fontSize: '20px',
    fontWeight: '500',
    marginTop: '-16px',
    marginBottom: '30px',
    lineHeight: '29px'
    // lineHeight: '30px'
}

const titleStyle = {
    textAlign: "center",
    fontFamily: 'Nunito',
    fontSize: '47px'
}

export default About